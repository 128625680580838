import { relativeTimeRounding } from "moment";

import btnClosePinkImg from '../images/btn_close_pink.png';
import btnCloseBlueImg from '../images/btn_close_blue.png';

var EventEmitter = require('events').EventEmitter;

function Students(scene, scaler, names, viewer) {
  this.scene = scene;
  this.scaler = scaler;
  this.viewer = viewer;
  this.depth = 60000;
  this.depthNames = 70000;
  this.group = this.scene.add.group();
  this.btnPortfolio;
  this.btnShare;
  this.nameSprites = [];

  this.dragSprites = [];
  this.names = names;
  this.allowDown = true;
  this.allowUp = true;

  this.on('dragstart-students', function () {
    this.dragSprites.map(sprite => {
      sprite.keepData.y = sprite.y;
    });
  });

  this.on('drag-students', function(evt) {
    if(evt.offset < 0 && this.allowUp == false)
      return;
    if(evt.offset > 0 && this.allowDown == false)
      return;
    let v = 2;
    this.dragSprites.map(sprite => {
      sprite.y = sprite.keepData.y + evt.offset * v;
    });
  });

  this.on('dragend-students', function(evt) {
    this.dragSprites.map(sprite => {
      sprite.alpha = sprite.keepData.alpha;
    });
  }, this);

  this.on('wheel-students', function(evt) {
    if(evt.offset < 0 && this.allowUp == false)
      return;
    if(evt.offset > 0 && this.allowDown == false)
      return;
    let v = 2;
    this.dragSprites.map(sprite => {
      sprite.y = sprite.keepData.y + evt.offset * v;
      sprite.keepData.y = sprite.y;
    });
  });
}

Students.prototype.__proto__ = EventEmitter.prototype;

Students.prototype.preload = function() {
  this.scene.textures.removeKey('btn_close_student');
  if(window.custom.selected.category == '研究生'){
    this.scene.load.image('btn_close_student', btnCloseBlueImg);
  } else{
    this.scene.load.image('btn_close_student', btnClosePinkImg);
  }
};

Students.prototype.create = function() {
  let sprite, designX, designY;

  this.displayBounds = {
    left: this.scaler.scaleX(165 + 210),
    top: this.scaler.scaleX(120),
    width: this.scaler.width -  this.scaler.hScale() * (165 + 86) - this.scaler.hScale() * 210,
    height: this.scaler.height - 2 * this.scaler.vScale() * 120
  };

  var bgColor = 0xF8DAE7;
  if(window.custom.selected.category == '研究生'){
    bgColor = 0xD5D9EE;
  }
  sprite = this.createColorBg(0, 0, this.scaler.width, this.scaler.height,  bgColor, 0.9);
  sprite.depth = this.depth;
  sprite.setInteractive();  // to disable drags behind the background
  this.group.add(sprite);
  this.bgSprite = sprite;
  this.bgSprite.setScale(this.scaler.width, this.scaler.height);
  
  // close button
  sprite = this.scene.add.image(
    this.scaler.width - this.scaler.hScale() * 72, 
    this.scaler.vScale() * 989, 
    'btn_close_student'
  );
  sprite.setOrigin(0.5, 0.5);
  sprite.depth = this.depthNames;
  this.scaler.scaleSpriteByHeight(sprite);

  sprite.setInteractive();
  sprite.on("pointerdown", pointer => {
    this.closeSprite.alpha = 0.6;
  });
  sprite.on("pointerup", pointer => {
    this.closeSprite.alpha = 1.0;
    this.hide();
  });
  this.group.add(sprite);
  this.closeSprite = sprite;


  this.nameList();

  this.createDragSprite();

  this.hide();
};

Students.prototype.update = function() {
  this.checkEnding();
};

Students.prototype.resize = function() {
  let screenWidth = this.scaler.width;
  let screenHeight = this.scaler.height;

  this.displayBounds = {
    left: this.scaler.scaleX(165 + 210),
    top: this.scaler.scaleX(120),
    width: this.scaler.width -  this.scaler.hScale() * (165 + 86) - this.scaler.hScale() * 210,
    height: this.scaler.height - 2 * this.scaler.vScale() * 120
  };
  //this.bgSprite.setScale(screenWidth, screenHeight);
  this.bgSprite.setScale(screenWidth, screenHeight);

  this.scaler.scaleSpriteByHeight(this.closeSprite);
  this.closeSprite.setPosition(
    this.scaler.width - this.scaler.hScale() * 72,
    this.scaler.vScale() * 989
  );

  this.dragSprite.setScale(screenWidth, screenHeight);

  this.nameListResize();
};

Students.prototype.nameList = function() {
  var self = this;

  let screenWidth = this.scaler.width;
  let screenHeight = this.scaler.height;

  var names = this.names;

  var cellWidth = this.scaler.hScale() * 354;
  var cellHeight = this.scaler.vScale() * 175;
  var spacingW = this.scaler.hScale() * 35;
  var spacingH = this.scaler.vScale() * 30;

  var pannelWidth = this.displayBounds.width;
  var pannelHeight = this.displayBounds.height;

  var cols = parseInt(pannelWidth/(cellWidth + spacingW));  

  // for(var i = 0; i < this.nameSprites.length; i ++){
  //   this.nameSprites[i].destroy();
  // }


  var left = (pannelWidth - (cellWidth + spacingW) * cols + spacingW)/2;

  this.dragSprites = [];
  
  for(var i = 0; i < names.length; i ++){
    (function(i){
      var r = parseInt(i/cols);
      var c = i%cols;

      var offsetX = (cellWidth + spacingW) * c;
      var offsetY = (cellHeight + spacingH) * r;

      var avatarSprite, authorSprite, colorBgSprite;

      var designX = left + self.displayBounds.left + offsetX;
      var designY = self.displayBounds.top + offsetY;
      var displayW = 42;
      var displayH = 175;
      var color = 0xc30080;
      if(window.custom.selected.category == '研究生'){
        color = 0x16169c;
      }
      colorBgSprite = self.createColorBg(
        designX, designY, displayW, displayH, color, 1
      );
      colorBgSprite.depth = self.depthNames;
      colorBgSprite.keepData = {
        y: colorBgSprite.y,
        alpha: 1,
        designX: designX
      };
      self.group.add(colorBgSprite);
      self.nameSprites.push(colorBgSprite);
      self.dragSprites.push(colorBgSprite);

      var designX = left + self.displayBounds.left + offsetX + 42 * self.scaler.vScale();
      var designY = self.displayBounds.top + offsetY;
      avatarSprite = self.scene.add.image(
        designX,
        designY,
        names[i].avatar
      );
      avatarSprite.setOrigin(0, 0);
      var scale_x = 175 * self.scaler.vScale() /avatarSprite.width;
      var scale_y = 175 * self.scaler.vScale() /avatarSprite.height;
      avatarSprite.setScale(scale_x, scale_y);
      avatarSprite.depth = self.depthNames;
      self.group.add(avatarSprite);
      self.nameSprites.push(avatarSprite);
      self.dragSprites.push(avatarSprite);
      avatarSprite.keepData = {
        y: avatarSprite.y,
        alpha: 1
      };      

      var designX = left + self.displayBounds.left + offsetX + avatarSprite.getBounds().width + self.scaler.vScale() * (25 + 42);
      authorSprite = self.scene.add.image(
        designX,
        designY,
        names[i].author, 0
      );
      authorSprite.setOrigin(0, 0);
      self.scaler.scaleSprite(authorSprite);
      authorSprite.depth = self.depthNames;
      self.group.add(authorSprite);
      self.nameSprites.push(authorSprite);
      self.dragSprites.push(authorSprite);
      authorSprite.keepData = {
        y: authorSprite.y,
        alpha: 1
      };

      [avatarSprite, authorSprite, colorBgSprite].map(sprite=>{
        sprite.setInteractive({ draggable: true });
        sprite.on("pointerdown", pointer => {
          if(self.dragMaxOffset < 5){
            avatarSprite.alpha = 0.5;
            authorSprite.alpha = 0.5;
            colorBgSprite.alpha = 0.5;
          }
        });
        sprite.on("pointerup", pointer => {
          if(self.dragMaxOffset < 5){
            self.hide();
            utils.setCookie('locator', names[i].region.json.meta.locator);
            self.viewer.jumpTo(names[i].region);
          }
        });
        // set drag events
        sprite.on('drag', function (pointer, dragX, dragY) {
          //console.log("draggin" + dragX);
          let offset = dragY - sprite.keepData.y;
          (Math.abs(offset) > self.dragMaxOffset) && (self.dragMaxOffset = Math.abs(offset));
          self.emit('drag-students', {offset: offset});
        }, this);
  
        sprite.on('dragstart', function (pointer, dragX, dragY) {
          //console.log(`drag start.. ${pointer.x} ${pointer.y}`);
          self.dragMaxOffset = 0;
          sprite.keepData.y = sprite.y;
          self.emit('dragstart-students');
        }, this);
  
        sprite.on('dragend', function (pointer, dragX, dragY) {
          self.emit('dragend-students');
        }, this);
  
        sprite.on('wheel', function (pointer, deltaX, deltaY, deltaZ) {
          self.emit('wheel-students', {offset: deltaY * 0.4});
        }, this);
      });
      
      // if(self.viewer.current && self.names[i].region.name == self.viewer.current.name){
      //   avatarSprite.alpha = 0.5;
      //   authorSprite.alpha = 0.5;
      //   colorBgSprite.alpha = 0.5;

      //   avatarSprite.keepData.alpha = 0.5;
      //   authorSprite.keepData.alpha = 0.5;
      //   colorBgSprite.keepData.alpha = 0.5;
      // }
    }(i));
  }

  // var timer = this.scene.time.delayedCall(50, function(){
  //   self.dragSprite.depth = self.depth;
  // }, this);

  // var timer = this.scene.time.delayedCall(70, function(){
  //   self.closeSprite.depth = self.depthNames;
  // }, this);
};

Students.prototype.nameListResize = function() {
  if(this.nameSprites.length <= 0)
    return;
  let screenWidth = this.scene.cameras.main.worldView.width;
  let screenHeight = this.scene.cameras.main.worldView.height;

  var cellWidth = this.scaler.hScale() * 354;
  var cellHeight = this.scaler.vScale() * 175;
  var spacingW = this.scaler.hScale() * 35;
  var spacingH = this.scaler.vScale() * 30;

  var pannelWidth = this.displayBounds.width;
  var pannelHeight = this.displayBounds.height;

  var cols = parseInt(pannelWidth/(cellWidth + spacingW));  

  var left = (pannelWidth - (cellWidth + spacingW) * cols + spacingW)/2;

  for(var i = 0; i < this.names.length; i ++){
    var r = parseInt(i/cols);
    var c = i%cols;

    var offsetX = (cellWidth + spacingW) * c;
    var offsetY = (cellHeight + spacingH) * r;

    var colorBgSprite = this.nameSprites[i * 3],
        avatarSprite = this.nameSprites[i*3 + 1],
        authorSprite = this.nameSprites[i*3 + 2];

    colorBgSprite.x = left + this.displayBounds.left + offsetX;
    colorBgSprite.y = this.displayBounds.top + offsetY;
    this.scaler.scaleSpriteByHeight(colorBgSprite);

    avatarSprite.x = left + this.displayBounds.left + offsetX + 42 * this.scaler.vScale();
    avatarSprite.y = this.displayBounds.top + offsetY;
    var scale_x = 175 * this.scaler.vScale() /avatarSprite.width;
    var scale_y = 175 * this.scaler.vScale() /avatarSprite.height;
    avatarSprite.setScale(scale_x, scale_y);

    authorSprite.x = left + this.displayBounds.left + offsetX + avatarSprite.getBounds().width + this.scaler.vScale() * (25 + 42);
    authorSprite.y = this.displayBounds.top + offsetY;
    this.scaler.scaleSprite(authorSprite);
  }

};

Students.prototype.setCurrent = function() {
  for(var i = 0; i < this.names.length; i ++){
    if(this.viewer.current && this.names[i].region.name == this.viewer.current.name){
      var colorBgSprite = this.nameSprites[i * 3],
        avatarSprite = this.nameSprites[i*3 + 1],
        authorSprite = this.nameSprites[i*3 + 2];

      avatarSprite.alpha = 0.5;
      authorSprite.alpha = 0.5;
      colorBgSprite.alpha = 0.5;

      avatarSprite.keepData.alpha = 0.5;
      authorSprite.keepData.alpha = 0.5;
      colorBgSprite.keepData.alpha = 0.5;
    }
  }
};

Students.prototype.hide = function() {
  this.group.setVisible(false);
};

Students.prototype.show = function() {
  this.group.setVisible(true);
};

Students.prototype.createColorBg = function(x, y, width, height, color, alpha){
  var rect = this.scene.add.rectangle(
    x,
    y,
    width,
    height
    , color, alpha);
  rect.setOrigin(0, 0);
  this.scaler.scaleSpriteByHeight(rect);
  return rect;
};

Students.prototype.createDragSprite = function () {
  var self = this;
  let dragSprite = this.createColorBg(0, 0, this.scaler.width, this.scaler.height, 0xff0000, 0);
  dragSprite.setInteractive({ draggable: true });
  // dragSprite.setOrigin(0, 0);
  // dragSprite.alpha = 0.5;
  dragSprite.keepData = { y: 0};
  dragSprite.depth = this.depth;

  this.dragSprite = dragSprite;
  this.dragSprite.setScale(this.scaler.width, this.scaler.height);

  this.group.add(this.dragSprite);

  dragSprite.on('dragstart', function (pointer, dragX, dragY) {
    // console.log(`drag start.. ${pointer.x} ${pointer.y}`);
    this.dragSprite.keepData.y = this.dragSprite.y;
    this.emit('dragstart-students');
  }, this);

  dragSprite.on('drag', function (pointer, dragX, dragY) {
    //console.log(dragX);
    // check whether out area
    this.emit('drag-students', {offset: dragY - this.dragSprite.keepData.y});

  }, this);

  dragSprite.on('dragend', function (pointer, dragX, dragY) {
    console.log('drag end');
    this.emit('dragend-students');
  }, this);

  dragSprite.on('wheel', function (pointer, deltaX, deltaY, deltaZ) {
    this.emit('wheel-students', {offset: deltaY * 0.4});
  }, this);


};

Students.prototype.checkEnding = function () {
  var minY, maxY;
  if(this.dragSprites.length <= 0)
    return;
  minY = this.dragSprites[0].y;
  maxY = this.dragSprites[this.dragSprites.length - 1].y;

  if(minY >= this.displayBounds.top && maxY < this.displayBounds.top + this.displayBounds.height - 175 * this.scaler.vScale()){
    this.allowDown = false;
    this.allowUp = false;
    return;
  }

  if(minY > this.displayBounds.top){
    this.allowDown = false;

    var offset = minY - this.displayBounds.top;
    this.dragSprites.map(sprite => {
      sprite.y -= offset; 
    });

  } else{
    this.allowDown = true;
  }

  if(maxY < this.displayBounds.top + this.displayBounds.height - 175 * this.scaler.vScale()){
    this.allowUp = false;

    if(minY < this.displayBounds.top){
      var offset = this.displayBounds.top + this.displayBounds.height - 175 * this.scaler.vScale() - maxY;
      this.dragSprites.map(sprite => {
        sprite.y += offset; 
      });
    }

  } else{
    this.allowUp = true;
  }
  
  // console.log("allowDown=" + this.allowDown);
  // console.log("allowUp=" + this.allowUp);

};

export default Students;