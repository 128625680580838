import topLeftTextImg0 from '../images/top_left_text/0.png';
import topLeftTextImg1 from '../images/top_left_text/1.png';
import topLeftTextImg2 from '../images/top_left_text/2.png';
import topLeftTextImg3 from '../images/top_left_text/3.png';

import topRightTextImg0 from '../images/top_right_text/0.png';
import topRightTextImg1 from '../images/top_right_text/1.png';
import topRightTextImg2 from '../images/top_right_text/2.png';
import topRightTextImg3 from '../images/top_right_text/3.png';
import topRightTextImg4 from '../images/top_right_text/4.png';

import bottomRightTextImg0 from '../images/bottom_right_text/0.png';
import bottomRightTextImg1 from '../images/bottom_right_text/1.png';
import bottomRightTextImg2 from '../images/bottom_right_text/2.png';

import loadingPinkImg from '../images/loading_pink.png';
import loadingFontPinkImg from '../images/loading_font_pink.png';
import loadingFontXml from '../images/loading_font.xml';


import logoImg from '../images/logo.png';

import btnSkipImg from '../images/btn_skip.png';

import transitionsVideo from '../archives/video/transitions.mp4';

function StartPage(scene, scaler) {
  this.scene = scene;
  this.scaler = scaler;
  this.group;

  this.topLeftGroup = [];
  this.topRightGroup = [];
  this.bottomLeftGroup = [];
  this.logoSprite;
  this.video;
  this.btnSkipSprite;

  this.toNextPage = false;

  this.videoIsReady = false;

  this.clickedEnterBtn = false;

  this.lastTime = null;
}

StartPage.prototype.preload = function() {
  this.scene.load.image('top_left_text_0', topLeftTextImg0);
  this.scene.load.image('top_left_text_1', topLeftTextImg1);
  this.scene.load.image('top_left_text_2', topLeftTextImg2);
  this.scene.load.image('top_left_text_3', topLeftTextImg3);

  this.scene.load.image('top_right_text_0', topRightTextImg0);
  this.scene.load.image('top_right_text_1', topRightTextImg1);
  this.scene.load.image('top_right_text_2', topRightTextImg2);
  this.scene.load.image('top_right_text_3', topRightTextImg3);
  this.scene.load.image('top_right_text_4', topRightTextImg4);

  this.scene.load.image('bottom_right_text_0', bottomRightTextImg0);
  this.scene.load.image('bottom_right_text_1', bottomRightTextImg1);
  this.scene.load.image('bottom_right_text_2', bottomRightTextImg2);

  this.scene.load.image('loading_pink', loadingPinkImg);

  this.scene.load.image('logo', logoImg);

  this.scene.load.image('btn_skip', btnSkipImg);
  // this.scene.load.video('transitions', transitionsVideo);

  this.scene.load.bitmapFont('loadingFontPink', loadingFontPinkImg, loadingFontXml);
};

StartPage.prototype.create = function() {
  var self = this;
  var sprite, designX, designY;

  this.group = this.scene.add.group();

  for(var i = 0; i < 4; i ++){
    designX = 60;
    designY = 60 + 78 * i;
    sprite = this.scene.add.image(
      this.scaler.scaleX(designX),
      this.scaler.scaleY(designY),
      'top_left_text_' + i);
    sprite.setOrigin(0, 0);
    // sprite.alpha = 0;
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      designX: designX,
      designY: designY
    };
    this.topLeftGroup.push(sprite);
    this.group.add(sprite);
  }

  for(var i = 0; i < 5; i ++){
    designX = 1866 - 90*i;
    designY = 60;
    sprite = this.scene.add.image(
      this.scaler.scaleX(designX),
      this.scaler.scaleY(designY),
      'top_right_text_' + i);
    sprite.setOrigin(1, 0);
    // sprite.alpha = 0;
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      designX: designX,
      designY: designY
    };
    this.topRightGroup.push(sprite);
    this.group.add(sprite);
  }

  for(var i = 0; i < 3; i ++){
    designX = 702;
    designY = [945, 978, 1003][i];
    sprite = this.scene.add.image(
      this.scaler.scaleX(designX),
      this.scaler.scaleY(designY),
      'bottom_right_text_' + i);
    sprite.setOrigin(0, 0);
    // sprite.alpha = 0;
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      designX: designX,
      designY: designY
    };
    this.bottomLeftGroup.push(sprite);
    this.group.add(sprite);
  }


  designX = 60;
  designY = 939;
  sprite = this.scene.add.image(
    this.scaler.scaleX(designX),
    this.scaler.scaleY(designY),
    'logo');
  sprite.setOrigin(0, 0);
  // sprite.alpha = 0;
  this.scaler.scaleSprite(sprite);
  sprite.keepData = {
    resized: true,
    designX: designX,
    designY: designY
  };
  this.logoSprite = sprite;
  this.group.add(sprite);

  designX = this.scaler.designRefWidth()/2;
  designY = this.scaler.designRefHeight()/2;
  sprite = this.scene.add.video(
    this.scaler.scaleX(designX),
    this.scaler.scaleY(designY)
    // 'transitions'
  );
  sprite.setOrigin(0.5, 0.5);
  sprite.loadURL(transitionsVideo);

  this.video = sprite;
  sprite.keepData = {
    resized: true,
    designX: designX,
    designY: designY
  };
  this.scaler.scaleSpriteByMax(sprite);
  this.video.visible = false;
  this.video.setVolume(0);

  sprite.on('created', function(video, width, height){
    console.log('#video created');
    
    this.scaler.scaleSpriteByMax(this.video);

    if(this.clickedEnterBtn == true){
      var sceneBoot = this.scene.scene.get('sceneBoot');
      sceneBoot.hideColourBar();
    }
    
  }, this);

  sprite.on('play', function(video){
    console.log('#video play');
  }, this);

  sprite.on('complete', function(video, width, height){
    // this.nextPage();
  }, this);

  this.video.on('error', function(video, error){
    console.log('VIDEO ERROR');
    console.log(error);
  }, this);

  designX = 0;
  designY = -72;
  sprite = this.scene.add.image(
    this.scaler.scaleX(this.scaler.designRefWidth()/2 + designX),
    this.scaler.scaleY(this.scaler.designRefHeight() + designY),
    'btn_skip'
  );
  sprite.setOrigin(0.5, 1);
  this.scaler.scaleSpriteByHeight(sprite);
  sprite.keepData = {
    resized: true,
    xlocation:'center',
    ylocation:'bottom',
    designX: designX,
    designY: designY
  };
  sprite.setInteractive();
  sprite.on("pointerdown", pointer => {
    this.btnSkipSprite.alpha = 0.6;
  });
  sprite.on("pointerup", pointer => {
    this.btnSkipSprite.alpha = 1.0;
    this.nextPage();  
  });
  this.btnSkipSprite = sprite;
  this.btnSkipSprite.alpha = 0;

  this.loadingGroup = this.createLoadingGroup();
};

StartPage.prototype.update = function() {
  // console.log(this.video.getDuration());
  if(utils.isIphone() == false && this.video.isPlaying() == false && this.video.getDuration() == 0){
    if(window.videoCannotPlay != true){
      window.videoCannotPlay = true;
      utils.setCookie('videoCannotPlay', window.videoCannotPlay);
    }
  }

  if(this.video.isPlaying() == false && this.video.getDuration() > 0){
    if(window.videoCannotPlay != false){
      window.videoCannotPlay = false;
      utils.setCookie('videoCannotPlay', window.videoCannotPlay);
    }
  }

  // if(this.videoIsReady == false && this.video.getDuration() >= 0){
  //   // console.log('video is ready');
  //   this.videoIsReady = true;
  // }

  if(this.clickedEnterBtn == true && this.toNextPage == false && this.video.isPlaying() && this.video.getDuration() - this.video.getCurrentTime() <= 1.5){
    this.nextPage();
  }

  //loading tip
  if(this.toNextPage == false && this.lastTime!= null){
    var now = new Date().getTime();
    if (now - this.lastTime >= 100) {

      if((this.video.getCurrentTime() - this.videoTime) * 1000 < 10){
        this.startLoadingAnimation();
      } else{
        this.stopLoadingAnimation();
      }

      this.lastTime = now;
      this.videoTime = this.video.getCurrentTime();
    }
  }
};

StartPage.prototype.resized = function() {
  if(this.scaler.width >= this.scaler.height){
    //横屏
    // this.textMobile.visible = false;
    this.logoSprite.visible = true;

    [this.topLeftGroup, this.topRightGroup, this.bottomLeftGroup].map(group=>{
      for(var i = 0; i < group.length; i++){
        group[i].visible = true;
      }
    });

    //tween resize
    for(var p in this.loadingGroup){
      var s = this.loadingGroup[p];
      if(p.indexOf('loadingIcon') >= 0){

        s.tween.remove();
        var delay = s.index * 100;
        s.tween = this.scene.tweens.add({
          targets: s,
          y: this.scaler.scaleY(this.scaler.designRefHeight()/2 + s.keepData.designY - 30),
          duration: 500,
          delay: delay,
          ease: 'Linear',
          repeat: -1,
          yoyo: true,
          onComplete: function () {
          },
        });
      }
    }

  } else{
    // this.textMobile.visible = true;
    // this.textMobile.setPosition(this.scaler.scaleX(0), this.scaler.scaleY(0));
    // this.scaler.scaleSprite(this.textMobile);

    this.logoSprite.visible = false;
    [this.topLeftGroup, this.topRightGroup, this.bottomLeftGroup].map(group=>{
      for(var i = 0; i < group.length; i++){
        group[i].visible = false;
      }
    });
  }
};

StartPage.prototype.topLeftAnimation = function(){
  for(var i = 0; i < this.topLeftGroup.length; i ++){
    var delay = 100*i;
    this.topLeftGroup[i].y = this.scaler.scaleY(this.topLeftGroup[i].keepData.designY - 30);
    this.scene.tweens.add({
      targets: this.topLeftGroup[i],
      x: this.scaler.scaleX(this.topLeftGroup[i].keepData.designX),
      y: this.scaler.scaleY(this.topLeftGroup[i].keepData.designY),
      alpha: 1,
      duration: 1000,
      delay: delay,
      ease: 'Linear',
      repeat: 0,
      // yoyo: true,
      onComplete: function () {
        // this.targets[0].keepData.resized = true;
      },
    });
  }
};

StartPage.prototype.topRightAnimation = function(){
  for(var i = 0; i < this.topRightGroup.length; i ++){
    var delay = 100*i;
    this.topRightGroup[i].x = this.scaler.scaleX(this.topRightGroup[i].keepData.designX + 30);
    this.scene.tweens.add({
      targets: this.topRightGroup[i],
      x: this.scaler.scaleX(this.topRightGroup[i].keepData.designX),
      y: this.scaler.scaleY(this.topRightGroup[i].keepData.designY),
      alpha: 1,
      duration: 1000,
      delay: delay,
      ease: 'Linear',
      repeat: 0,
      // yoyo: true,
      onComplete: function () {
      },
    });
  }
};

StartPage.prototype.bottomLeftAnimation = function(){
  var self = this;
  for(var i = 0; i < this.bottomLeftGroup.length; i ++){
    var delay = 100*i;
    this.bottomLeftGroup[i].y = this.scaler.scaleY(this.bottomLeftGroup[i].keepData.designY - 30);
    this.scene.tweens.add({
      targets: this.bottomLeftGroup[i],
      x: this.scaler.scaleX(this.bottomLeftGroup[i].keepData.designX),
      y: this.scaler.scaleY(this.bottomLeftGroup[i].keepData.designY),
      alpha: 1,
      duration: 1000,
      delay: delay,
      ease: 'Linear',
      repeat: 0,
      // yoyo: true,
      onComplete: function () {
      },
    });
  }
};

StartPage.prototype.logoAnimation = function(){
  var self = this;
  this.logoSprite.y = this.scaler.scaleY(this.logoSprite.keepData.designY - 30);
  this.scene.tweens.add({
    targets: this.logoSprite,
    x: this.scaler.scaleX(this.logoSprite.keepData.designX),
    y: this.scaler.scaleY(this.logoSprite.keepData.designY),
    alpha: 1,
    duration: 1000,
    delay: 0,
    ease: 'Linear',
    repeat: 0,
    // yoyo: true,
    onComplete: function () {
    },
  });
};

StartPage.prototype.enterAnimation = function(){
  this.topLeftAnimation();
  this.topRightAnimation();
  this.bottomLeftAnimation();
  this.logoAnimation();
};

StartPage.prototype.playVideo = function(){
  this.clickedEnterBtn = true;

  if(window.videoCannotPlay == true){
    this.nextPage();
    return;
  }

  this.video.setVolume(1);
  this.video.setCurrentTime(0);
  this.video.play();
  this.lastTime = new Date().getTime();
  this.videoTime = this.video.getCurrentTime();;

  this.video.visible = true;
  this.video.alpha = 0;
  this.scene.tweens.add({
    targets: this.video,
    alpha: 1,
    duration: 600,
    delay: 0,
    ease: 'Linear',
    repeat: 0,
    // yoyo: true,
    onComplete: function () {
    },
  });

  this.scene.tweens.add({
    targets: this.btnSkipSprite,
    alpha: 1,
    duration: 600,
    delay: 0,
    ease: 'Linear',
    repeat: 0,
    // yoyo: true,
    onComplete: function () {
    },
  });
};

StartPage.prototype.nextPage =  function(){
  this.toNextPage = true;
  this.btnSkipSprite.visible = false;
  this.stopLoadingAnimation();

  this.scene.tweens.add({
    targets: this.video,
    alpha: 0,
    duration: 600,
    delay: 500,
    ease: 'Linear',
    repeat: 0,
    // yoyo: true,
    onComplete: function () {
      this.targets[0].setPaused(true);
      this.targets[0].visible = false;
      this.targets[0].destroy();
    },
  });

  this.scene.scene.launch('sceneGuide');
};

StartPage.prototype.showAllElements =  function(){
  if(!this.group)
    return;
  var children = this.group.getChildren();
  for(var i = 0; i < children.length; i ++){
    children[i].visible = true;
  }
};

StartPage.prototype.hideAllElements =  function(){
  if(!this.group)
    return;

  try {
    var children = this.group.getChildren();

    for(var i = 0; i < children.length; i ++){
      children[i].visible = false;
    }
  }
  catch(err) {
    console.log(err);
  }

};

StartPage.prototype.getCookie = function(){
  var selected = null;
  try {
    selected = JSON.parse(decodeURIComponent(utils.getCookie('selected')));
  }
  catch(err) {
    console.log(err);
  }

  window.custom.locator = utils.getCookie('locator');

  if(selected == null)
    return;

  window.custom.selected = selected;

  var self = this;
  var latest = '';

  var systemList = window.custom.systemList;
  for(var key in systemList){
    var sys = systemList[key];
    if(sys.label.indexOf(selected.name) >= 0 && sys.label.indexOf(selected.category) >= 0){
      latest = sys.latest;
      break;
    }
  }

  var sceneGuide = this.scene.scene.get('sceneGuide');
  sceneGuide.latest = latest;

  this.scene.scene.start('sceneGuide', {autoJump: true, type: 'region', noTip: true});
};

StartPage.prototype.createLoadingGroup = function(){
  var group = {};
  var designX, designY, sprite;

  var x = [-56, 0, 56];
  for(var i = 0; i < x.length; i ++){
    designX = x[i];
    designY = 0;
    sprite = this.scene.add.image(
      this.scaler.scaleX(this.scaler.designRefWidth()/2 + designX),
      this.scaler.scaleY(this.scaler.designRefHeight()/2 + designY),
      'loading_pink'
    );
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSpriteByHeight(sprite);
    sprite.index = i;
    sprite.keepData = {
      resized: true,
      xlocation:'center',
      ylocation:'center',
      designX: designX,
      designY: designY
    };
    group['loadingIcon' + i] = sprite;
    sprite.visible = false;

    var delay = i * 100;
    sprite.tween = this.scene.tweens.add({
      targets: sprite,
      y: this.scaler.scaleY(this.scaler.designRefHeight()/2 + designY - 30),
      duration: 500,
      delay: delay,
      ease: 'Linear',
      repeat: -1,
      yoyo: true,
      onComplete: function () {
      },
    });
  }

  designX = -80;
  designY =  50;
  sprite = this.scene.add.bitmapText(
    this.scaler.scaleX(this.scaler.designRefWidth()/2 + designX),
    this.scaler.scaleY(designY),
    'loadingFontPink',
    '', 24).setOrigin(0, 0.5).setCenterAlign();
  this.scaler.scaleSpriteByHeight(sprite);
  sprite.keepData = {
    resized: true,
    xlocation:'center',
    ylocation:'center',
    designX: designX,
    designY: designY
  };
  // sprite.setLetterSpacing(this.scaler.vScale() * 5);
  sprite.setText('L');
  group.loadingTextSprite = sprite;
  sprite.visible = false;

  return group;
};

StartPage.prototype.startLoadingAnimation = function(){
  for(var p in this.loadingGroup){
    var sprite = this.loadingGroup[p];
    if(p.indexOf('loadingIcon') >= 0){
      sprite.tween.resume();
    }
  }

  for(var p in this.loadingGroup){
    var sprite = this.loadingGroup[p];
    sprite.visible = true;
  }
};

StartPage.prototype.stopLoadingAnimation = function(){
  for(var p in this.loadingGroup){
    var sprite = this.loadingGroup[p];
    if(p.indexOf('loadingIcon') >= 0){
      sprite.tween.pause();
    }

    sprite.visible = false;
  }
};

export default StartPage;