import bgDotBlackImg from '../images/bg_dot_black.png';
import btnCloseImg from '../images/btn_close.png';
import btnLeftImg from '../images/btn_left.png';
import btnRightImg from '../images/btn_right.png';
import btnZoomInImg from '../images/btn_zoom_in.png';
import btnZoomOutImg from '../images/btn_zoom_out.png';

import GC from "./const";

function Portfolio(scene, scaler) {
  this.scene = scene;
  this.scaler = scaler;
  this.depth = 60000;
  this.group = this.scene.add.group();
  this.bgSprite;
  this.closeSprite;
  this.leftSprite;
  this.rightSprite;
  this.labelSprite;
  this.zoomInSprite;
  this.zoomOutSprite;

  this.current;
  this.currentItem;
  this.imageSprite;
  this.imageDragSprite;
  this.videoSprites = [];

  this.zoom = 1.0;

  this.videoProgress;
  this.loadState = GC.UNLOAD;
  this.createState = GC.DESTROYED;

  this.targetItem;
}

Portfolio.prototype.setMaterials = function(materials) {
  this.materials = materials;
};

Portfolio.prototype.setItems = function(items) {
  this.items = items;
};

Portfolio.prototype.setVideos = function (videos) {
  this.videos = videos;
};

Portfolio.prototype.preload = function() {
  this.scene.load.image('bg_dot_black', bgDotBlackImg);
  this.scene.load.image('btn_close', btnCloseImg);
  this.scene.load.image('btn_left', btnLeftImg);
  this.scene.load.image('btn_right', btnRightImg);

  this.scene.load.image('btn_zoom_in', btnZoomInImg);
  this.scene.load.image('btn_zoom_out', btnZoomOutImg);
};

Portfolio.prototype.create = function() {

  let sprite;

  // add the temporary sprite for image
  sprite = this.scene.add.image(0, 0, 'bg_dot_black');
  sprite.setOrigin(0, 0);
  //bgSprite.setScale(
  //  this.scene.cameras.main.worldView.width,
  //  this.scene.cameras.main.worldView.height
  //);
  //sprite.keepData = {
  //  resized: true
  //};
  sprite.depth = this.depth;
  sprite.setInteractive();  // to disable drags behind the background
  this.group.add(sprite);
  this.bgSprite = sprite;
  //bgSprite.setInteractive();

  // close button
  sprite = this.scene.add.image(0, 0, 'btn_close');
  sprite.setOrigin(0, 1);
  //this.scaler.scaleSpriteByHeight(closeSprite);
  sprite.depth = this.depth;

  sprite.setInteractive();
  sprite.on("pointerdown", pointer => {
    this.closeSprite.alpha = 0.6;
  });
  sprite.on("pointerup", pointer => {
    this.closeSprite.alpha = 1.0;
    this.hide();
    // bgSprite.destroy();
    // assetSprite.destroy();
    // closeSprite.destroy();
  });
  this.group.add(sprite);
  this.closeSprite = sprite;

  // left button
  sprite = this.scene.add.image(0, 0, 'btn_left');
  sprite.setOrigin(0.5, 0.5);
  //this.scaler.scaleSpriteByHeight(closeSprite);
  sprite.depth = this.depth;

  sprite.setInteractive();
  sprite.on("pointerdown", pointer => {
    this.leftSprite.alpha = 0.6;
  });
  sprite.on("pointerup", pointer => {
    this.leftSprite.alpha = 1.0;
    this.prev();
    // bgSprite.destroy();
    // assetSprite.destroy();
    // closeSprite.destroy();
  });
  this.group.add(sprite);
  this.leftSprite = sprite;

  // right button
  sprite = this.scene.add.image(0, 0, 'btn_right');
  sprite.setOrigin(0.5, 0.5);
  //this.scaler.scaleSpriteByHeight(closeSprite);
  sprite.depth = this.depth;

  sprite.setInteractive();
  sprite.on("pointerdown", pointer => {
    this.rightSprite.alpha = 0.6;
  });
  sprite.on("pointerup", pointer => {
    this.rightSprite.alpha = 1.0;
    this.next();
    // bgSprite.destroy();
    // assetSprite.destroy();
    // closeSprite.destroy();
  });
  this.group.add(sprite);
  this.rightSprite = sprite;

  //zoom in button
  sprite = this.scene.add.image(0, 0, 'btn_zoom_in');
  sprite.setOrigin(0, 0.5);
  sprite.depth = this.depth;
  sprite.visible = false;

  sprite.setInteractive();
  sprite.on("pointerdown", pointer => {
    this.zoomInSprite.alpha = 0.6;
  });
  sprite.on("pointerup", pointer => {
    this.zoomInSprite.alpha = 1.0;

    let centerBefore = {};
    if (this.imageSprite.width < this.imageSprite.keepData.tileCropWidth * this.zoom) {
      centerBefore.x = this.imageSprite.width * 0.5 / this.zoom;
    } else {
      // myabe cropwidth should be original image size
      centerBefore.x = this.imageSprite.keepData.tileCropWidth * 0.5;
    }
    if (this.imageSprite.height < this.imageSprite.keepData.tileCropHeight * this.zoom) {
      centerBefore.y = this.imageSprite.height * 0.5 / this.zoom;
    } else {
      centerBefore.y = this.imageSprite.keepData.tileCropHeight * 0.5;
    }

    this.zoom *= 1.5;
    this.imageSprite.setTileScale(this.zoom);
    this.imageSprite.setCrop(0, 0,
      this.imageSprite.keepData.tileCropWidth * this.zoom,
      this.imageSprite.keepData.tileCropHeight * this.zoom
    );

    let centerAfter = {};
    if (this.imageSprite.width < this.imageSprite.keepData.tileCropWidth * this.zoom) {
      centerAfter.x = this.imageSprite.width * 0.5 / this.zoom;
    } else {
      // myabe cropwidth should be original image size
      centerAfter.x = this.imageSprite.keepData.tileCropWidth * 0.5;
    }
    if (this.imageSprite.height < this.imageSprite.keepData.tileCropHeight * this.zoom) {
      centerAfter.y = this.imageSprite.height * 0.5 / this.zoom;
    } else {
      centerAfter.y = this.imageSprite.keepData.tileCropHeight * 0.5;
    }

    if (this.imageSprite.width < this.imageSprite.keepData.tileCropWidth * this.zoom) {
      this.imageSprite.tilePositionX -= (centerAfter.x - centerBefore.x);
      if (this.imageSprite.tilePositionX < 0) {
        this.imageSprite.tilePositionX = 0;
      }
      let showWidth = (this.imageSprite.keepData.tileCropWidth - this.imageSprite.tilePositionX) * this.zoom;
      if (this.imageSprite.width > showWidth) {
        this.imageSprite.tilePositionX -= (this.imageSprite.width - showWidth) / this.zoom;
      }
    } else {
      this.imageSprite.tilePositionX = 0;
    }
    if (this.imageSprite.height < this.imageSprite.keepData.tileCropHeight * this.zoom) {
      this.imageSprite.tilePositionY -= (centerAfter.y - centerBefore.y);
      if (this.imageSprite.tilePositionY < 0) {
        this.imageSprite.tilePositionY = 0;
      }
      let showHeight = (this.imageSprite.keepData.tileCropHeight - this.imageSprite.tilePositionY) * this.zoom;
      if (this.imageSprite.height > showHeight) {
        this.imageSprite.tilePositionY -= (this.imageSprite.height - showHeight) / this.zoom;
      }
    } else {
      this.imageSprite.tilePositionY = 0;
    }

    if (this.imageSprite.keepData.tileCropWidth * this.zoom < this.displayBounds.width) {
      this.imageSprite.width = this.imageSprite.keepData.tileCropWidth * this.zoom;
    } else {
      this.imageSprite.width = this.displayBounds.width;
    }
    if (this.imageSprite.keepData.tileCropHeight * this.zoom < this.displayBounds.height) {
      this.imageSprite.height = this.imageSprite.keepData.tileCropHeight * this.zoom;
    } else {
      this.imageSprite.height = this.displayBounds.height;
    }

    // this.updatePortfolioTexture();
    // var width = 200 * this.zoom;
    // var height = 200 * this.zoom;
    // var startX = (this.zoomTexture.width - width)/2;
    // var startY = (this.zoomTexture.height - height)/2;
    // var ctx = this.zoomTexture.getContext('2d')
    // var image = this.scene.textures.get(this.currentItem.texture).getSourceImage();

    // this.zoomTexture.clear();
    // ctx.drawImage (image, startX, startY, width, height);
  });
  // this.group.add(sprite);
  this.zoomInSprite = sprite;

  //zoom out button
  sprite = this.scene.add.image(0, 0, 'btn_zoom_out');
  sprite.setOrigin(0, 0.5);
  sprite.depth = this.depth;
  sprite.visible = false;

  sprite.setInteractive();
  sprite.on("pointerdown", pointer => {
    this.zoomOutSprite.alpha = 0.6;
  });
  sprite.on("pointerup", pointer => {
    this.zoomOutSprite.alpha = 1.0;

    let centerBefore = {};
    if (this.imageSprite.width < this.imageSprite.keepData.tileCropWidth * this.zoom) {
      centerBefore.x = this.imageSprite.width * 0.5 / this.zoom;
    } else {
      // myabe cropwidth should be original image size
      centerBefore.x = this.imageSprite.keepData.tileCropWidth * 0.5;
    }
    if (this.imageSprite.height < this.imageSprite.keepData.tileCropHeight * this.zoom) {
      centerBefore.y = this.imageSprite.height * 0.5 / this.zoom;
    } else {
      centerBefore.y = this.imageSprite.keepData.tileCropHeight * 0.5;
    }

    this.zoom /= 1.5;
    this.imageSprite.setTileScale(this.zoom);
    this.imageSprite.setCrop(0, 0,
      this.imageSprite.keepData.tileCropWidth * this.zoom,
      this.imageSprite.keepData.tileCropHeight * this.zoom
    );

    let centerAfter = {};
    if (this.imageSprite.width < this.imageSprite.keepData.tileCropWidth * this.zoom) {
      centerAfter.x = this.imageSprite.width * 0.5 / this.zoom;
    } else {
      // myabe cropwidth should be original image size
      centerAfter.x = this.imageSprite.keepData.tileCropWidth * 0.5;
    }
    if (this.imageSprite.height < this.imageSprite.keepData.tileCropHeight * this.zoom) {
      centerAfter.y = this.imageSprite.height * 0.5 / this.zoom;
    } else {
      centerAfter.y = this.imageSprite.keepData.tileCropHeight * 0.5;
    }

    if (this.imageSprite.width < this.imageSprite.keepData.tileCropWidth * this.zoom) {
      this.imageSprite.tilePositionX -= (centerAfter.x - centerBefore.x);
      if (this.imageSprite.tilePositionX < 0) {
        this.imageSprite.tilePositionX = 0;
      }
      let showWidth = (this.imageSprite.keepData.tileCropWidth - this.imageSprite.tilePositionX) * this.zoom;
      if (this.imageSprite.width > showWidth) {
        this.imageSprite.tilePositionX -= (this.imageSprite.width - showWidth) / this.zoom;
      }
    } else {
      this.imageSprite.tilePositionX = 0;
    }
    if (this.imageSprite.height < this.imageSprite.keepData.tileCropHeight * this.zoom) {
      this.imageSprite.tilePositionY -= (centerAfter.y - centerBefore.y);
      if (this.imageSprite.tilePositionY < 0) {
        this.imageSprite.tilePositionY = 0;
      }
      let showHeight = (this.imageSprite.keepData.tileCropHeight - this.imageSprite.tilePositionY) * this.zoom;
      if (this.imageSprite.height > showHeight) {
        this.imageSprite.tilePositionY -= (this.imageSprite.height - showHeight) / this.zoom;
      }
    } else {
      this.imageSprite.tilePositionY = 0;
    }

    if (this.imageSprite.keepData.tileCropWidth * this.zoom < this.displayBounds.width) {
      this.imageSprite.width = this.imageSprite.keepData.tileCropWidth * this.zoom;
    } else {
      this.imageSprite.width = this.displayBounds.width;
    }
    if (this.imageSprite.keepData.tileCropHeight * this.zoom < this.displayBounds.height) {
      this.imageSprite.height = this.imageSprite.keepData.tileCropHeight * this.zoom;
    } else {
      this.imageSprite.height = this.displayBounds.height;
    }

    // var width = 200 * this.zoom;
    // var height = 200 * this.zoom;
    // var startX = (this.zoomTexture.width - width)/2;
    // var startY = (this.zoomTexture.height - height)/2;
    // var ctx = this.zoomTexture.getContext('2d')
    // var image = this.scene.textures.get(this.currentItem.texture).getSourceImage();

    // this.zoomTexture.clear();
    // ctx.drawImage (image, startX, startY, width, height);

  });
  // this.group.add(sprite);
  this.zoomOutSprite = sprite;

  // label textf
  // let style = {
  //   font: "32px Arial",
  //   fill: "#ffffff",
  //   align: "center",
  // }
  sprite = this.scene.add.bitmapText(
    0,
    0,
    'numberFont2',
    '', 30);
  sprite.setCenterAlign();
  sprite.setOrigin(0.5, 0);
  //this.scaler.scaleSpriteByHeight(closeSprite);
  sprite.depth = this.depth;
  this.group.add(sprite);
  this.labelSprite = sprite;

  let style = {
    font: "32px Arial",
    fill: "#ffffff",
    align: "center",
  };
  sprite = this.scene.add.text(
    this.scaler.width,
    this.scaler.height,
    '',
    style);
  sprite.setOrigin(1, 1);
  sprite.depth = this.depth;
  this.group.add(sprite);
  this.videoProgress = sprite;
  this.videoProgress.alpha = 0;

   // video cover sprite
   sprite = this.scene.add.image(0, 0, 'empty');
   sprite.setOrigin(0.5, 0.5);
   sprite.depth = this.depth;
   this.group.add(sprite);
   this.coverSprite = sprite;

  // video play sprite
  sprite = this.scene.add.image(0, 0, 'btn_play');
  sprite.setOrigin(0.5, 0.5);
  this.group.add(sprite);
  sprite.depth = this.depth + 10000;
  sprite.on("pointerdown", pointer => {
    this.playSprite.alpha = 0.6;
  });
  sprite.on("pointerup", pointer => {
    this.playSprite.alpha = 1.0;
    // this.videoSprites[this.current] && this.videoSprites[this.current].play();

    this.videoSprites[this.current] && this.videoSprites[this.current].setPaused(!this.videoSprites[this.current].isPaused());
    this.playSprite.visible = false;
    this.coverSprite.visible = false;
  });
  sprite.setInteractive();
  this.playSprite = sprite;

  // image sprite
  sprite = this.scene.add.image(0, 0, 'empty');
  sprite.setOrigin(0.5, 0.5);
  sprite.depth = this.depth;
  this.group.add(sprite);
  this.imageSprite = sprite;



  // image sprite
  /*sprite = this.scene.add.tileSprite(0, 0, 500, 500, 'empty');
  sprite.setOrigin(0.5, 0.5);
  sprite.depth = this.depth;
  sprite.keepData = {
    x:0,
    y:0,
    tileX:0,
    tileY:0,
    tileCropWidth:0,
    tileCropHeight:0,
  };

  this.group.add(sprite);
  this.imageSprite = sprite;
  this.imageSprite.setTileScale(this.zoom);

  // image drag sprite
  sprite = this.scene.add.image(0, 0, 'empty');
  sprite.setOrigin(0.5, 0.5);
  sprite.setAlpha(0.2);
  sprite.depth = this.depth + 1;
  sprite.setInteractive({ draggable: true });
  sprite.on('dragstart', function (pointer, dragX, dragY) {
    //console.log(`${this.imageSprite.tilePositionX} ${this.imageSprite.tilePositionY}`)
    this.imageSprite.keepData.x = this.imageSprite.x;
    this.imageSprite.keepData.y = this.imageSprite.y;
    this.imageSprite.keepData.tileX = this.imageSprite.tilePositionX;
    this.imageSprite.keepData.tileY = this.imageSprite.tilePositionY;
    //console.log(`drag start.. ${pointer.x} ${pointer.y}`);
    // this.pointSprite.visible = true;
    // this.pointSprite.x = pointer.x;
    // this.pointSprite.y = pointer.y;
    // this.dragSprite.keepData.x = this.dragSprite.x;
    // this.emit('dragstart');
  }, this);

  sprite.on('drag', function (pointer, dragX, dragY) {
    console.log(`${dragX} ${dragX}`)
    //this.emit('drag', {offset: dragX - this.dragSprite.keepData.x});
    let offsetX = this.imageSprite.keepData.x - dragX;
    let offsetY = this.imageSprite.keepData.y - dragY;
    let marginX = this.imageSprite.keepData.tileCropWidth * this.zoom  - this.imageSprite.width;
    let marginY = this.imageSprite.keepData.tileCropHeight * this.zoom  - this.imageSprite.height;
    //console.log(`${marginX} ${this.imageSprite.keepData.tileX + offsetX} ${this.scaler.hScale()} ${this.zoom} ${this.imageSprite.width}`)
    if (this.imageSprite.keepData.tileX + offsetX >= 0
      && this.imageSprite.keepData.tileX + offsetX <= marginX/this.zoom) {
      this.imageSprite.tilePositionX = this.imageSprite.keepData.tileX + offsetX;
    }
    if (this.imageSprite.keepData.tileY + offsetY >= 0
      && this.imageSprite.keepData.tileY + offsetY <= marginY/this.zoom) {
      this.imageSprite.tilePositionY = this.imageSprite.keepData.tileY + offsetY;
    }
  }, this);

  sprite.on('dragend', function (pointer, dragX, dragY) {
  }, this);

  sprite.on('wheel', function (pointer, deltaX, deltaY, deltaZ) {
    //this.emit('wheel', {offset: deltaY * 0.4});
  }, this);

  this.group.add(sprite);
  this.imageDragSprite = sprite;*/


  this.hide();

  window.v = this;
};

Portfolio.prototype.update = function() {
  if(this.videoSprites[this.current]){
    var progress = this.videoSprites[this.current].getProgress() * 100;
    this.videoProgress.setText(progress.toFixed(2) + '%');
  }

  this.checkLoading();
};

Portfolio.prototype.loadAndOpen = function(target_item) {
  if(target_item){
    this.targetItem = target_item;
  } else{
    var indexs = [];
    for(var key in this.items){
      indexs.push(key.split('__')[1]);
    }
    var min = Math.min.apply(null, indexs);
    this.targetItem = 'item__' + min;
  }

  let portfolioKeys = [];
  _.keys(this.items).forEach(key => {
    portfolioKeys.push(this.items[key]['texture']);
  });

  let regionImages = this.getMaterials("images");
  Object.keys(regionImages).map(key => {
    if (portfolioKeys.indexOf(key) >= 0){
      this.scene.load.image(key, window.publicPath + regionImages[key]);
    }
  });

  let regionVideos = this.getMaterials("videos");
  Object.keys(regionVideos).map(key => {
    if (portfolioKeys.indexOf(key) >= 0){
      this.scene.load.image(key, window.publicPath + regionVideos[key].replace(/\.(mp4|mov)$/i, '.cover.jpg'));
    }
  });
  // this.scene.load.removeListener('progress')
  // this.scene.load.on('progress', function (progress){
  //   console.log("progress=" + progress);
  // }, this);

  // this.scene.load.removeListener('complete')
  // this.scene.load.on('complete', function (key, type, data) {
  //   // Your handler code
  //   console.log('complete##');

  //   this.open('item__1');
  // }, this);

  if(this.createState == GC.CREATED){
    this.open(this.targetItem);
  } else{
    this.scene.load.start();
    this.loadState = GC.LOADED;
  }

};

Portfolio.prototype.loadingProgress = function() {
  let total = 0;
  let missing = 0;

  let portfolioKeys = [];
  _.keys(this.items).forEach(key => {
    portfolioKeys.push(this.items[key]['texture']);
  });

  let regionImages = this.getMaterials("images");
  let regionVideos = this.getMaterials("videos");
  let sources = Object.assign(regionImages, regionVideos);
  Object.keys(sources).map(key => {
    total ++;
    if (portfolioKeys.indexOf(key) < 0) {
      // simply pass it
    } else if (this.scene.textures.get(key).key === '__MISSING') {
      missing ++;
    }
  });

  return (total == 0 ) ? 1.0 : (total - missing) / total;
};

Portfolio.prototype.checkLoading = function() {

  if (this.loadState == GC.LOADED && this.createState == GC.DESTROYED) {
    let progress = this.loadingProgress();
    if (progress >= 1.0) {
      this.open(this.targetItem);
      this.createState = GC.CREATED;
    } 
  }
};


Portfolio.prototype.loadVideos = function() {
  let sprite;
  for(let i = 0; i < _.keys(this.items).length; i ++) {
    let item = this.items[_.keys(this.items)[i]];
    if (item && item.mimeType.split('/')[0] == 'video'){
      // video sprite
      sprite = this.scene.add.video(0, 0);
      sprite.setOrigin(0.5, 0.5);
      sprite.depth = this.depth;
      this.group.add(sprite);
      if(window.videoCannotPlay != true){
        sprite.loadURL(window.publicPath + this.videos[item.texture]);
      }
      sprite.on('created', function(video, width, height){
        let videoSprite = this.videoSprites[this.current];
        let [dw, dh] = this.assetSize(width, height);
        videoSprite.setDisplaySize(dw, dh);
        videoSprite.keepData.videoReady = true;

        videoSprite.setInteractive();

        this.coverSprite.setDisplaySize(dw, dh);
      }, this);
      sprite.on('complete', function(video, width, height){
        let videoSprite = this.videoSprites[this.current];
        if(window.videoCannotPlay != true){
          this.playSprite.visible = true;
        }
      }, this);
      sprite.keepData = {
        videoReady: false //
      };
      sprite.setPaused(true);

      // sprite.setInteractive();
      sprite.on("pointerdown", pointer => {
        console.log("video ponter up");
      });
      sprite.on("pointerup", pointer => {
        console.log("video ponter down");
        let videoSprite = this.videoSprites[this.current];

        // console.log(videoSprite.isPaused())
        videoSprite.setPaused(!videoSprite.isPaused());
        // videoSprite.stop();
        // we simply close the cover is video played
        if (videoSprite.isPaused()) {
          this.playSprite.visible = true;
        } else {
          this.playSprite.visible = false;
        }
      });


      //this.videoSprites[this.current].setPaused(true);
      this.videoSprites[i] = sprite;
    }
  }
  this.videoLoaded = true;
};

Portfolio.prototype.open = function(key) {
  if (!this.videoLoaded) {
    this.loadVideos();
  }

  this.current = -1;
  for(let i = 0; i < _.keys(this.items).length; i ++) {
    if (_.keys(this.items)[i] === key) {
      this.current = i;
    }
  }
  if (this.current >= 0) {
    this.currentItem = this.items[key];
    this.show();
  }
};

Portfolio.prototype.assetSize = function (assetWidth, assetHeight) {
  let ratio;
  if (this.displayBounds.width/this.displayBounds.height > assetWidth/assetHeight) {
    ratio = assetWidth / assetHeight;
    return [this.displayBounds.height * ratio , this.displayBounds.height];
  } else {
    ratio = assetHeight / assetWidth;
    return [this.displayBounds.width , this.displayBounds.width * ratio];
  }

  return ratio;
};

Portfolio.prototype.show = function() {
  this.group.setVisible(true);
  if (this.current == 0) {
    this.leftSprite.visible = false;
  }
  if (this.current == _.keys(this.items).length - 1) {
    this.rightSprite.visible = false;
  }

  // disable all by default
  this.imageSprite.visible = false;
  // this.imageDragSprite.visible = false;
  _.forEach(this.videoSprites, sprite => {
    sprite && (sprite.visible = false);
  });
  //this.videoSprite.visible = false;
  this.playSprite.visible = false;

  if (this.currentItem && this.currentItem.mimeType.split('/')[0] == 'image'){
    this.imageSprite.visible = true;
    // this.imageDragSprite.visible = true;
    this.imageSprite.setTexture(this.currentItem.texture);
    this.imageSprite.setScale(1);



    // var image = this.scene.textures.get(this.currentItem.texture).getSourceImage();
    // this.imageSprite.keepData.tileCropWidth = /*this.scaler.hScale() * */image.width;
    // this.imageSprite.keepData.tileCropHeight = /*this.scaler.vScale() * */image.height;
    // this.imageSprite.setCrop(0, 0,
    //   this.imageSprite.keepData.tileCropWidth * this.zoom,
    //   this.imageSprite.keepData.tileCropHeight * this.zoom
    // );
    //this.imageSprite.width = image.width;
    // this.imageSprite.height = image.height;

    this.coverSprite.visible = false;
    // this.videoProgress.visible = false;
  } else if (this.currentItem && this.currentItem.mimeType.split('/')[0] == 'video'){
    if(window.videoCannotPlay != true){
      this.videoSprites[this.current] && (this.videoSprites[this.current].visible = true);
      this.playSprite.visible = true;
    }

    this.coverSprite.setTexture(this.currentItem.texture);
    this.coverSprite.visible = true;
    // this.videoProgress.visible = true;
  }

  this.resize();
};

Portfolio.prototype.next = function() {
  this.stop();

  if (!this.items || _.keys(this.items).length < 1) {
    return;
  }

  this.current = (this.current + 1) % _.keys(this.items).length;
  this.currentItem = this.items[_.keys(this.items)[this.current]];
  this.show();
};

Portfolio.prototype.prev = function() {
  this.stop();

  if (!this.items || _.keys(this.items).length < 1) {
    return;
  }
  this.current = (this.current - 1);
  if (this.current < 0) {
    this.current = _.keys(this.items).length;
  }
  this.currentItem = this.items[_.keys(this.items)[this.current]];
  this.show();
};

Portfolio.prototype.hide = function() {
  this.stop();
  this.group.setVisible(false);
};

Portfolio.prototype.stop = function() {
  if (this.currentItem && this.currentItem.mimeType.split('/')[0] == 'video'){
    this.videoSprites[this.current].setPaused(true);

    //this.videoSprite.stop();
    /*
    let sprite = this.scene.add.video(0, 0);
    sprite.setOrigin(0.5, 0.5);
    this.group.add(sprite);
    sprite.on('created', function(video, width, height){
      let ratio = this.assetRatio(width, height);
      this.videoSprite.setScale(ratio, ratio);
    }, this);
    this.videoSprite =sprite;
    */

  }
};

Portfolio.prototype.resize = function() {
  let screenWidth = this.scaler.width;
  let screenHeight = this.scaler.height;

  this.displayBounds = {
    left: this.scaler.scaleX(240 + 190),
    top: this.scaler.scaleX(135),
    width: this.scaler.width -  2 * this.scaler.hScale() * 240 - this.scaler.hScale() * 190,
    height: this.scaler.height - 2 * this.scaler.vScale() * 135
  };

  //this.bgSprite.setScale(screenWidth, screenHeight);
  this.bgSprite.setScale(screenWidth, screenHeight);

  this.scaler.scaleSpriteByHeight(this.closeSprite);
  this.closeSprite.setPosition(
    this.displayBounds.left + this.displayBounds.width,
    this.displayBounds.top
  );

  this.scaler.scaleSpriteByHeight(this.leftSprite);
  this.leftSprite.setPosition(
    this.displayBounds.left - this.scaler.hScale() * (20 + 190/2),
    screenHeight/2
  );

  this.scaler.scaleSpriteByHeight(this.rightSprite);
  this.rightSprite.setPosition(
    this.displayBounds.left + this.displayBounds.width + this.scaler.hScale() * (20 + 190/2),
    screenHeight/2
  );

  this.scaler.scaleSpriteByHeight(this.zoomInSprite);
  this.zoomInSprite.setPosition(
    this.displayBounds.left + this.displayBounds.width + this.scaler.hScale() * 20,
    screenHeight - this.scaler.vScale() * (270 + 90)
  );

  this.scaler.scaleSpriteByHeight(this.zoomOutSprite);
  this.zoomOutSprite.setPosition(
    this.displayBounds.left + this.displayBounds.width + this.scaler.hScale() * 20,
    screenHeight - this.scaler.vScale() * 270
  );

  this.scaler.scaleSpriteByHeight(this.labelSprite);
  this.labelSprite.setPosition(
    screenWidth/2 + this.scaler.scaleX(190/2),
    this.displayBounds.top + this.displayBounds.height + this.scaler.vScale() * 38
  );
  this.labelSprite.setText('' + (this.current + 1) + ' / ' + _.keys(this.items).length);


  //this.videoSprite.setScale(1);
  if (this.videoSprites[this.current]) {
    let videoSprite = this.videoSprites[this.current];
    if(videoSprite.keepData.videoReady == true) {
      let [dw, dh] = this.assetSize(
        videoSprite.getBounds().width,
        videoSprite.getBounds().height
      );
      videoSprite.setDisplaySize(dw, dh);
    }
    videoSprite.setPosition(screenWidth/2 + this.scaler.scaleX(190/2), screenHeight/2);
    
    let [dw, dh] = this.assetSize(
      this.coverSprite.getBounds().width,
      this.coverSprite.getBounds().height
    );
    this.coverSprite.setDisplaySize(dw, dh);
    this.coverSprite.setPosition(screenWidth/2 + this.scaler.scaleX(190/2), screenHeight/2);
  } else if (this.imageSprite) {
    let [dw, dh] = this.assetSize(
      this.imageSprite.getBounds().width,
      this.imageSprite.getBounds().height
    );
    // dw = 300;
    // dh = 300;
    this.imageSprite.setDisplaySize(dw, dh);
    this.imageSprite.setSize(this.displayBounds.width, this.displayBounds.height);
    this.imageSprite.setPosition(screenWidth/2 + this.scaler.scaleX(190/2), screenHeight/2);

    // this.imageDragSprite.setPosition(screenWidth/2 + this.scaler.scaleX(190/2), screenHeight/2);
    // this.imageDragSprite.setScale(this.displayBounds.width, this.displayBounds.height);
  }


  this.scaler.scaleSpriteByHeight(this.playSprite);
  this.playSprite.setPosition(screenWidth/2  + this.scaler.scaleX(190/2), screenHeight/2);

  // resize all image and videos
  // this.imageSprite.setTexture(this.currentItem.texture);
  // let ratio = this.assetRatio(
  //   this.imageSprite.getBounds().width,
  //   this.imageSprite.getBounds().height
  // );
  // this.imageSprite.setScale(ratio, ratio);


};

Portfolio.prototype.isEmpty = function() {
  if(this.items && JSON.stringify(this.items) !== '{}'){
    return false;
  } else {
    return true;
  }
};

Portfolio.prototype.updatePortfolioTexture = function() {
  var image = this.scene.textures.get(this.currentItem.texture).getSourceImage();

  this.zoomTexture.setSize(this.zoomTextureWidth, this.zoomTextureHeight);
  var ctx = this.zoomTexture.getContext('2d');
  var width = image.width * this.zoom;
  var height = image.height * this.zoom;

  var sx = (width - image.width)/2 / this.zoom;
  var sy = 0;//(height - image.height)/2 / this.zoom;
  var swidth;
  var sheight;

  var dx, dy;
  var drawWidth;
  var drawHeight;
  if(width > height){
    swidth = image.width / this.zoom;
    sheight = height;//swidth * height/width;


    drawWidth = this.zoomTextureWidth;
    drawHeight = image.height / this.zoom;

    dx = 0;
    dy = (this.zoomTextureHeight - drawHeight)/2;
    // sy += (this.zoomTextureHeight - drawHeight)/2;
  } else{
    sheight = image.height;
    swidth = sheight * width/height;

    // drawHeight = this.zoomTextureHeight;
    // drawWidth = drawHeight * width/height;
    // sx += (this.zoomTextureWidth - drawWidth)/2;
  }

  // var swidth = this.zoomTextureWidth * this.zoom;
  // var sheight = this.zoomTextureHeight * this.zoom;
  // var sx = (this.zoomTextureWidth - swidth)/2;
  // var sy = (this.zoomTextureHeight - sheight)/2;

  // var swidth = image.width;
  // var sheight = image.height;
  // var sx =

  this.zoomTexture.clear();
  ctx.drawImage(image, sx, sy, swidth, sheight, dx, dy, drawWidth, drawHeight);

  this.imageSprite.setTexture('canvastexture');
};

Portfolio.prototype.getMaterials = function(key) {
  let materials = {};
  if(this.materials && this.materials[key])
  materials = this.materials[key];

  return materials;
};

export default Portfolio;
