
module.exports = {

  width: 0,
  height: 0,

  _designRefWidth: 0,
  _designRefHeight: 0,

  config: function(width, height, designRefWidth, designRefHeight) {
    this.width = width;
    this.height = height;
    this._designRefWidth = designRefWidth;
    this._designRefHeight = designRefHeight;

    //this._designRefWidth = 1080 * width / height;
    //this._designRefHeight = 1080;
  },

  designRefWidth: function() {
    return this._designRefWidth;
  },

  designRefHeight: function() {
    return this._designRefHeight;
  },

  scaleSprite: function(sprite) {
    sprite.setScale(this.hScale(), this.vScale());
  },

  scaleSpriteByHeight: function(sprite) {
    sprite.setScale(this.vScale(), this.vScale());
  },

  scaleX: function (designX, align) {
    let alignment = (align || 'middle').toLowerCase();

    if (alignment == 'top') {
        return designX * this.hScale();
    } else if (alignment == 'bottom') {
        return this.offsetPos().x * 2 + designX * this.hScale();
    } else {
        return this.offsetPos().x + designX * this.hScale();
    }
  },

  scaleY: function(designY, align, offset) {
    let alignment = (align || 'middle').toLowerCase();
    offset = offset || 0;
    if(alignment == 'top') {
        return this.offsetPos().y * offset + designY * this.vScale();
    } else if (alignment == 'bottom') {
        return this.offsetPos().y * 2 + designY * this.vScale();
    } else {
        return this.offsetPos().y + designY * this.vScale();
    }
  },

  hScale: function() {
    // return(this.game.canvas.width / this._designRefWidth);
    if(this.offsetPos().x != 0)
        return (this.height / this._designRefHeight);
    else
        return (this.width / this._designRefWidth);
  },

  vScale: function() {
    // return(this.game.canvas.height / this._designRefHeight);
    if(this.offsetPos().y != 0)
        return (this.width / this._designRefWidth);
    else
        return (this.height / this._designRefHeight);
  },

  offsetPos: function() {
    var offsetX = 0;
    var offsetY = 0;
    var ws = this.width / this._designRefWidth;
    var hs = this.height / this._designRefHeight;
    if(ws < hs){
        offsetY = (this.height - this._designRefHeight * this.width/this._designRefWidth)/2;
    }else{
        offsetX = (this.width - this._designRefWidth * this.height/this._designRefHeight)/2;
    }
    return {x: offsetX, y: offsetY};
  },

  scaleSpriteFill: function(sprite) {
    sprite.setScale(this.width / sprite.width, this.height / sprite.height);
  },  

  scaleSpriteByMax: function(sprite) {
    var rationScreen = this.width/this.height;
    var rationSprite = sprite.width/sprite.height;
    var scale;
    if(rationScreen >= rationSprite){
      scale = this.width / sprite.width;
    } else{
      scale = this.height / sprite.height;
    }
    sprite.setScale(scale, scale);
  },  

  scaleBgSprite: function(sprite) {
    sprite.setScale(this.width / this._designRefWidth, this.height / this._designRefHeight);
  },

  scaleBgX: function(designX) {
    return designX * (this.width / this._designRefWidth);
  },

  scaleBgY: function(designY) {
    return designY * (this.height / this._designRefHeight);
  }

};

