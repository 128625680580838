  'use strict';

import textGuideImg0 from '../images/text_guide/0.png';
import textGuideImg1 from '../images/text_guide/1.png';

import pannelPinkImg from '../images/wall.png';

import systemNameImg0 from '../images/system_name/0.png';
import systemNameImg1 from '../images/system_name/1.png';
import systemNameImg2 from '../images/system_name/2.png';
import systemNameImg3 from '../images/system_name/3.png';
import systemNameImg4 from '../images/system_name/4.png';
import systemNameImg5 from '../images/system_name/5.png';
import systemNameImg6 from '../images/system_name/6.png';
import systemNameImg7 from '../images/system_name/7.png';
import systemNameImg8 from '../images/system_name/8.png';
import systemNameImg9 from '../images/system_name/9.png';
import systemNameImg10 from '../images/system_name/10.png';
import systemNameImg11 from '../images/system_name/11.png';

import flipingImg0 from '../images/fliping/0.png';
import flipingImg1 from '../images/fliping/1.png';
import flipingImg2 from '../images/fliping/2.png';
import flipingImg3 from '../images/fliping/3.png';
import flipingImg4 from '../images/fliping/4.png';
import flipingImg5 from '../images/fliping/5.png';
import flipingImg6 from '../images/fliping/6.png';
import flipingImg7 from '../images/fliping/7.png';
import flipingImg8 from '../images/fliping/8.png';
import flipingImg9 from '../images/fliping/9.png';
import flipingImg10 from '../images/fliping/10.png';
import flipingImg11 from '../images/fliping/11.png';
import flipingImg12 from '../images/fliping/12.png';
import flipingImg13 from '../images/fliping/13.png';
import flipingImg14 from '../images/fliping/14.png';
import flipingImg15 from '../images/fliping/15.png';
import flipingImg16 from '../images/fliping/16.png';
import flipingImg17 from '../images/fliping/17.png';
import flipingImg18 from '../images/fliping/18.png';
import flipingImg19 from '../images/fliping/19.png';
import flipingImg20 from '../images/fliping/20.png';
import flipingImg21 from '../images/fliping/21.png';
import flipingImg22 from '../images/fliping/22.png';
import flipingImg23 from '../images/fliping/23.png';
import flipingImg24 from '../images/fliping/24.png';
import flipingImg25 from '../images/fliping/25.png';
import flipingImg26 from '../images/fliping/26.png';
import flipingImg27 from '../images/fliping/27.png';
import flipingImg28 from '../images/fliping/28.png';
import flipingImg29 from '../images/fliping/29.png';
import flipingImg30 from '../images/fliping/30.png';
import flipingImg31 from '../images/fliping/31.png';

import systemSlideImg0 from '../images/system_slide/0.png';
import systemSlideImg1 from '../images/system_slide/1.png';
import systemSlideImg2 from '../images/system_slide/2.png';
import systemSlideImg3 from '../images/system_slide/3.png';
import systemSlideImg4 from '../images/system_slide/4.png';
import systemSlideImg5 from '../images/system_slide/5.png';
import systemSlideImg6 from '../images/system_slide/6.png';
import systemSlideImg7 from '../images/system_slide/7.png';
import systemSlideImg8 from '../images/system_slide/8.png';
import systemSlideImg9 from '../images/system_slide/9.png';
import systemSlideImg10 from '../images/system_slide/10.png';
import systemSlideImg11 from '../images/system_slide/11.png';

import slideGraduateImg from '../images/silde_graduate.png';
import slideUndergraduateImg from '../images/silde_undergraduate.png';

import bgWhiteImg from '../images/bg_wh.png';
import bgImg from '../images/bg.png';

import titleENImg0 from '../images/system_description/title_en/0.png';
import titleENImg1 from '../images/system_description/title_en/1.png';
import titleENImg2 from '../images/system_description/title_en/2.png';
import titleENImg3 from '../images/system_description/title_en/3.png';
import titleENImg4 from '../images/system_description/title_en/4.png';
import titleENImg5 from '../images/system_description/title_en/5.png';
import titleENImg6 from '../images/system_description/title_en/6.png';
import titleENImg7 from '../images/system_description/title_en/7.png';
import titleENImg8 from '../images/system_description/title_en/8.png';
import titleENImg9 from '../images/system_description/title_en/9.png';
import titleENImg10 from '../images/system_description/title_en/10.png';
import titleENImg11 from '../images/system_description/title_en/11.png';

import titleCNImg0 from '../images/system_description/title_cn/0.png';
import titleCNImg1 from '../images/system_description/title_cn/1.png';
import titleCNImg2 from '../images/system_description/title_cn/2.png';
import titleCNImg3 from '../images/system_description/title_cn/3.png';
import titleCNImg4 from '../images/system_description/title_cn/4.png';
import titleCNImg5 from '../images/system_description/title_cn/5.png';
import titleCNImg6 from '../images/system_description/title_cn/6.png';
import titleCNImg7 from '../images/system_description/title_cn/7.png';
import titleCNImg8 from '../images/system_description/title_cn/8.png';
import titleCNImg9 from '../images/system_description/title_cn/9.png';
import titleCNImg10 from '../images/system_description/title_cn/10.png';
import titleCNImg11 from '../images/system_description/title_cn/11.png';

import contentENImg0 from '../images/system_description/content_en/0.png';
import contentENImg1 from '../images/system_description/content_en/1.png';
import contentENImg2 from '../images/system_description/content_en/2.png';
import contentENImg3 from '../images/system_description/content_en/3.png';
import contentENImg4 from '../images/system_description/content_en/4.png';
import contentENImg5 from '../images/system_description/content_en/5.png';
import contentENImg6 from '../images/system_description/content_en/6.png';
import contentENImg7 from '../images/system_description/content_en/7.png';
import contentENImg8 from '../images/system_description/content_en/8.png';
import contentENImg9 from '../images/system_description/content_en/9.png';
import contentENImg10 from '../images/system_description/content_en/10.png';
import contentENImg11 from '../images/system_description/content_en/11.png';

import contentCNImg0 from '../images/system_description/content_cn/0.png';
import contentCNImg1 from '../images/system_description/content_cn/1.png';
import contentCNImg2 from '../images/system_description/content_cn/2.png';
import contentCNImg3 from '../images/system_description/content_cn/3.png';
import contentCNImg4 from '../images/system_description/content_cn/4.png';
import contentCNImg5 from '../images/system_description/content_cn/5.png';
import contentCNImg6 from '../images/system_description/content_cn/6.png';
import contentCNImg7 from '../images/system_description/content_cn/7.png';
import contentCNImg8 from '../images/system_description/content_cn/8.png';
import contentCNImg9 from '../images/system_description/content_cn/9.png';
import contentCNImg10 from '../images/system_description/content_cn/10.png';
import contentCNImg11 from '../images/system_description/content_cn/11.png';

import btnLanEnImg from '../images/btn_lan_en.png';
import btnLanCnImg from '../images/btn_lan_cn.png';
import btnRightImg from '../images/btn_right.png';
import btnLeftImg from '../images/btn_left.png';
import bgBlueImg from '../images/bg_blue.png';
import bgPinkImg from '../images/bg_pink.png';
import textGraduateImg from '../images/text_graduate.png';
import textUndergraduateImg from '../images/text_undergraduate.png';
import btnCloseImg from '../images/btn_close.png';

import btnStudentsImg from '../images/btn_students.png';
import btnHallImg from '../images/btn_hall.png';
import btnChangePinkImg from '../images/btn_change_pink.png';
import btnChangeBlueImg from '../images/btn_change_blue.png';

import loadingPinkImg from '../images/loading_pink.png';
import loadingBlueImg from '../images/loading_blue.png';
import btnRefreshPinkImg from '../images/btn_refresh_pink.png';
import btnRefreshBlueImg from '../images/btn_refresh_blue.png';

import prefaceTitleImg from '../images/preface/title.png';
import prefaceContentCNImg from '../images/preface/content_cn.png';
import prefaceContentEN0Img from '../images/preface/content_en_0.png';
import prefaceContentEN1Img from '../images/preface/content_en_1.png';
import btnNextImg from '../images/preface/btn_next.png';
import btnPrevImg from '../images/preface/btn_prev.png';

import loadingFontBlueImg from '../images/loading_font_blue.png';
import loadingFontPinkImg from '../images/loading_font_pink.png';
import loadingFontXml from '../images/loading_font.xml';


// import galleryf7a5 from "../dataset/galleries-f7a5.txt";
// import galleryd9db from "../dataset/galleries-d9db.txt";
// import gallery39c6 from "../dataset/galleries-39c6.txt";
// import galleryf4af from "../dataset/galleries-f4af.txt";
import gallery18df from "../dataset/galleries-18df.txt";

var SceneGuide = new Phaser.Class({

  Extends: Phaser.Scene,

  initialize:

  function SceneGuide ()
  {
    console.log('scene guide....');
    Phaser.Scene.call(this, { key: 'sceneGuide', active: false });

    this.galleriesMaps = {
      // 'https://exhibition.ad.tsinghua.edu.cn': gallery18df,
      // 'http://exhibition-origin.ad.onekind.com.cn': galleryd9db,
      // 'http://exhibition-staging.ad.onekind.com.cn': gallery39c6,
      'default': gallery18df
    };

    this.latest = '';
    // this.fetchRegions();
    this.descriptionSpriteTweenParams = {};

    this.step = 'guide';
    this.language = 'cn';
    this.prefaceContentPage = 0;

    this.duration = {
      enter: 800,
      preface: 600,
      systemListIn: 600,
      systemListOut: 600,
      descriptionIn: 600,
      descriptionOut: 600,
      category: 600,
      sidebarButtons: 600
    };
    this.sidebarWidth = 160;

    this.sidebarOffset = 0;
    if(window.needOffset == true){
      this.sidebarOffset = 70;
    }
  },

  init: function(params){
    var sceneBoot = this.scene.get('sceneBoot');
    this.scaler = sceneBoot.scaler;

    this.params = params;

    this.allElements = [];
    this.enterElements = [];

    this.sceneBoot = this.scene.get('sceneBoot');
    this.sceneBoot.scaleMode = 'height';
    this.sceneBoot.windowResized = true;

    sceneBoot.currentScene = 'sceneGuide';

    this.sceneMain = this.scene.get('sceneMain');
    this.canShowLoading = false;
  },

  preload: function ()
  {
    console.log('scene guide preload..');
    var self = this;
    var textGuideImgs = [textGuideImg0, textGuideImg1];
    for(var i = 0; i < textGuideImgs.length; i ++){
      this.load.image('text_guide' + i, textGuideImgs[i]);
    }

    var systemNameImgs = [systemNameImg0, systemNameImg1, systemNameImg2, systemNameImg3, systemNameImg4, systemNameImg5,
                          systemNameImg6, systemNameImg7, systemNameImg8, systemNameImg9, systemNameImg10, systemNameImg11];
    for(var i = 0; i < systemNameImgs.length; i ++){
      this.load.image('system_name_' + i, systemNameImgs[i]);
    }

    var flipingImgs = [flipingImg0, flipingImg1, flipingImg2, flipingImg3, flipingImg4, flipingImg5, flipingImg6, flipingImg7, flipingImg8, flipingImg9,
                       flipingImg10, flipingImg11, flipingImg12, flipingImg13, flipingImg14, flipingImg15, flipingImg16, flipingImg17, flipingImg18, flipingImg19,
                       flipingImg20, flipingImg21, flipingImg22, flipingImg23, flipingImg24, flipingImg25, flipingImg26, flipingImg27, flipingImg28, flipingImg29,
                       flipingImg30, flipingImg31];

    for(var i = 0; i < flipingImgs.length; i ++){
      this.load.image('fliping_' + i, flipingImgs[i]);
    }


    var systemSlideImgs = [systemSlideImg0, systemSlideImg1, systemSlideImg2, systemSlideImg3, systemSlideImg4, systemSlideImg5,
                      systemSlideImg6, systemSlideImg7, systemSlideImg8, systemSlideImg9, systemSlideImg10, systemSlideImg11];

    for(var i = 0; i < systemSlideImgs.length; i ++){
      this.load.image('system_slide_' + i, systemSlideImgs[i]);
    }

    this.load.image('pannel_pink', pannelPinkImg);

    var titleENImgs = [titleENImg0, titleENImg1, titleENImg2, titleENImg3, titleENImg4, titleENImg5,
                        titleENImg6, titleENImg7, titleENImg8, titleENImg9, titleENImg10, titleENImg11];
    for(var i = 0; i < titleENImgs.length; i ++){
      this.load.image('title_en_' + i, titleENImgs[i]);
    }

    var titleCNImgs = [titleCNImg0, titleCNImg1, titleCNImg2, titleCNImg3, titleCNImg4, titleCNImg5,
                        titleCNImg6, titleCNImg7, titleCNImg8, titleCNImg9, titleCNImg10, titleCNImg11];
    for(var i = 0; i < titleENImgs.length; i ++){
      this.load.image('title_cn_' + i, titleCNImgs[i]);
    }

    var contentENImgs = [contentENImg0, contentENImg1, contentENImg2, contentENImg3, contentENImg4, contentENImg5,
                        contentENImg6, contentENImg7, contentENImg8,contentENImg9, contentENImg10, contentENImg11];
    for(var i = 0; i < contentENImgs.length; i ++){
      this.load.image('content_en_' + i, contentENImgs[i]);
    }

    var contentCNImgs = [contentCNImg0, contentCNImg1, contentCNImg2, contentCNImg3, contentCNImg4, contentCNImg5,
                        contentCNImg6, contentCNImg7, contentCNImg8,contentCNImg9, contentCNImg10, contentCNImg11];
    for(var i = 0; i < contentENImgs.length; i ++){
      this.load.image('content_cn_' + i, contentCNImgs[i]);
    }

    this.load.image('silde_graduate', slideGraduateImg);
    this.load.image('silde_undergraduate', slideUndergraduateImg);
    this.load.image('btn_lan_en', btnLanEnImg);
    this.load.image('btn_lan_cn', btnLanCnImg);
    this.load.image('btn_right', btnRightImg);
    this.load.image('btn_left', btnLeftImg);
    this.load.image('bg_blue', bgBlueImg);
    this.load.image('bg_pink', bgPinkImg);
    this.load.image('text_graduate', textGraduateImg);
    this.load.image('text_undergraduate', textUndergraduateImg);
    this.load.image('btn_close', btnCloseImg);

    this.load.image('btn_students', btnStudentsImg);
    this.load.image('btn_hall', btnHallImg);
    this.load.image('btn_change_pink', btnChangePinkImg);
    this.load.image('btn_change_blue', btnChangeBlueImg);

    this.load.image('loading_pink', loadingPinkImg);
    this.load.image('loading_blue', loadingBlueImg);
    this.load.image('btn_refresh_pink', btnRefreshPinkImg);
    this.load.image('btn_refresh_blue', btnRefreshBlueImg);

    this.load.image('preface_title', prefaceTitleImg);
    this.load.image('preface_content_cn', prefaceContentCNImg);
    this.load.image('preface_content_en_0', prefaceContentEN0Img);
    this.load.image('preface_content_en_1', prefaceContentEN1Img);
    this.load.image('btn_next', btnNextImg);
    this.load.image('btn_prev', btnPrevImg);

    this.load.bitmapFont('loadingFontBlue', loadingFontBlueImg, loadingFontXml);
    this.load.bitmapFont('loadingFontPink', loadingFontPinkImg, loadingFontXml);

    
    this.canShowLoading = false;
    var timer = this.time.delayedCall(2000, function(){
      self.canShowLoading = false;
    }, this);

    this.load.on('progress', function (progress){
      if(progress >= 1) {
        if(this.scene.get('sceneEnter')){
          this.scene.get('sceneEnter').startPage.stopLoadingAnimation();
        }

        if(this.scene.get('sceneCountdown')){
          this.scene.get('sceneCountdown').startPage.stopLoadingAnimation();
        }
      } else{
        
        if(this.canShowLoading == true && this.scene.get('sceneEnter')){
          this.scene.get('sceneEnter').startPage.startLoadingAnimation();
        }

        if(this.canShowLoading == true && this.scene.get('sceneCountdown')){
          this.scene.get('sceneCountdown').startPage.startLoadingAnimation();
        }  
        
      }
    }, this);
  },

  create: function ()
  {
    var self = this;

    var designX, designY, sprite;

    this.loadingGroup = this.createLoadingGroup();

    this.textGuideGroup = [];

    for(var i = 0; i < 2; i ++){
      designX = 60;
      if(i == 0){
        designX = 60 + this.sidebarOffset;
      }
      designY = [50, 837][i];
      sprite = this.add.image(
        this.scaler.scaleX(designX),
        this.scaler.scaleY(designY),
        'text_guide' + i
      );
      sprite.setOrigin(0, 0);
      sprite.keepData = {
        resized: true,
        designX: designX,
        designY: designY
      };
      this.scaler.scaleSpriteByHeight(sprite);
      sprite.alpha = 0;

      this.textGuideGroup.push(sprite);
      this.enterElements.push(sprite);
    }

    // button refresh
    designX = 86 + this.sidebarOffset;
    designY = this.scaler.designRefHeight()/2;
    sprite = this.add.image(
      this.scaler.scaleX(designX),
      this.scaler.scaleY(designY),
      'btn_left'
    );
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSpriteByHeight(sprite);
    sprite.keepData = {
      resized: true,
      designX: designX,
      designY: designY
    };
    this.btnBack = sprite;
    this.btnBack.alpha = 0;
    this.enterElements.push(sprite);

    [this.textGuideGroup[0], this.btnBack].map(sprite => {
      sprite.setInteractive();
      sprite.on("pointerdown", pointer => {
        sprite.alpha = 0.6;
      });
      sprite.on("pointerup", pointer => {
        sprite.alpha = 1.0;
        window.location.reload();
      });
    })
  

    this.prefaceGroup = this.createPrefaceGroup();

    this.pannelGroup = this.createSystemsPannel();

    this.graduateGroup = this.createGraduateGroup();

    this.undergraduateGroup = this.createUndergraduateGroup();

    this.descriptionGroup = this.createDescriptionGroup();

    // button back to preface
    designX = -1287;
    designY = this.scaler.designRefHeight()/2;
    sprite = this.add.image(
      this.scaler.scaleX(this.scaler.designRefWidth() + designX),
      this.scaler.scaleY(designY),
      'btn_left'
    );
    sprite.setOrigin(1, 0.5);
    this.scaler.scaleSpriteByHeight(sprite);
    sprite.keepData = {
      resized: false,
      location: 'right',
      designX: designX,
      designY: designY
    };
    sprite.setInteractive();
    sprite.on("pointerdown", pointer => {
      this.btnBackToPrefaceSprite.alpha = 0.6;
    });
    sprite.on("pointerup", pointer => {
      this.btnBackToPrefaceSprite.alpha = 1.0;
      this.backToPreface();
    });
    this.btnBackToPrefaceSprite = sprite;
    this.btnBackToPrefaceSprite.alpha = 0;
    this.btnBackToPrefaceSprite.displayWidth = 0;
    this.btnBackToPrefaceSprite.displayHeight = 0;


    designX = 95;
    designY = 964;
    sprite = this.add.image(
      this.scaler.scaleX(designX),
      this.scaler.scaleY(designY),
      'btn_left'
    );
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSpriteByHeight(sprite);
    sprite.depth = 1;
    sprite.keepData = {
      resized: true,
      designX: designX,
      designY: designY
    };
    sprite.setInteractive();
    sprite.on("pointerdown", pointer => {
      this.btnTriangleBackSprite.alpha = 0.6;
    });
    sprite.on("pointerup", pointer => {
      this.btnTriangleBackSprite.alpha = 1.0;

      this.backToDescription(true);
    });
    this.btnTriangleBackSprite = sprite;
    this.btnTriangleBackSprite.visible = false;

    designX = 95 + this.sidebarOffset;
    designY = 328;
    sprite = this.add.image(
      this.scaler.scaleX(designX),
      this.scaler.scaleY(designY),
      'silde_graduate'
    );
    sprite.setOrigin(0, 0);
    this.scaler.scaleSpriteByHeight(sprite);
    sprite.keepData = {
      resized: true,
      designX: designX,
      designY: designY
    };
    this.typeSprite = sprite;
    this.typeSprite.visible = false;

    designX = this.sidebarWidth/2 + this.sidebarOffset;
    designY = 850;
    sprite = this.add.image(
      this.scaler.scaleX(designX),
      this.scaler.scaleY(designY),
      'btn_students'
    );
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSpriteByHeight(sprite);
    sprite.keepData = {
      resized: true,
      designX: designX,
      designY: designY,
      x: sprite.x
    };
    // sprite.depth = this.sidebarDepth;

    sprite.setInteractive();
    sprite.on("pointerdown", pointer => {
      this.btnStudents.alpha = 0.6;
    });
    sprite.on("pointerup", pointer => {
      this.btnStudents.alpha = 1.0;

      if (this.sceneMain.viewer.current.portfolio)
        this.sceneMain.viewer.current.portfolio.hide();
      
      this.sceneMain.iconSlideSprite.visible = false;
      this.sceneMain.viewer.current.students.show();
    });
    this.btnStudents = sprite;
    this.btnStudents.alpha = 0;

    // hall button
    designX = this.sidebarWidth/2 + this.sidebarOffset;
    designY = 982;
    sprite = this.add.image(
      this.scaler.scaleX(designX),
      this.scaler.scaleY(designY),
      'btn_hall'
    );
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSpriteByHeight(sprite);
    sprite.keepData = {
      resized: true,
      designX: designX,
      designY: designY,
      x: sprite.x
    };
    // sprite.depth = this.sidebarDepth;

    sprite.setInteractive();
    sprite.on("pointerdown", pointer => {
      this.btnHall.alpha = 0.6;
    });
    sprite.on("pointerup", pointer => {
      this.btnHall.alpha = 1.0;
      // window.location.reload();

      this.delCookies();
      var sceneBoot = this.scene.get('sceneBoot');
      sceneBoot.hideRegionBg();
      
      this.scene.start('sceneGuide', {autoJump: true, type: 'back-animation', noTip: true});
    });
    this.btnHall = sprite;
    this.btnHall.alpha = 0;


    designX = this.sidebarWidth/2 + this.sidebarOffset;
    designY = 718;
    sprite = this.add.image(
      this.scaler.scaleX(designX),
      this.scaler.scaleY(designY),
      'btn_change_pink'
    );
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSpriteByHeight(sprite);
    sprite.keepData = {
      resized: true,
      designX: designX,
      designY: designY,
      x: sprite.x
    };
    // sprite.depth = this.sidebarDepth;

    sprite.setInteractive();
    sprite.on("pointerdown", pointer => {
      this.btnChange.alpha = 0.6;
    });
    sprite.on("pointerup", pointer => {
      this.btnChange.alpha = 1.0;
      this.tochangeType();
    });
    this.btnChange = sprite;
    this.btnChange.alpha = 0;


    for(var i = 0; i < this.pannelGroup.length; i ++){
      for(var p in this.pannelGroup[i]){
        this.pannelGroup[i][p].visible = false;
      }
    }

    this.flipingTimer = this.time.addEvent({
      delay: 6000,
      callback: function(){
        self.flipingAnimation();
      },
      callbackScope: this,
      loop: true
    });


    if(this.params.autoJump){
      switch(this.params.type){
        case 'back-animation':
          self.step = 'guide';

          for(var p in this.prefaceGroup){
            var sprite = this.prefaceGroup[p];
            sprite.visible = false;
          }
          //
          this.graduateGroup.bgSprite.disableInteractive();
          this.undergraduateGroup.bgSprite.disableInteractive();
          this.btnTriangleBackSprite.disableInteractive();
          
          this.categoryBackAnimation(false, function(){

            self.sceneMain.scene.setVisible(false);
            self.sceneMain.updating = false;
            self.sceneMain.scene.stop();

            self.stopLoadingAnimation();


            // self.btnTriangleBackSprite.visible = true;
            var timer1 = self.time.delayedCall(50, function(){
              self.backToDescription(false, function(){
                var timer2 = self.time.delayedCall(50, function(){
                  self.toCloseDescription( function(){
                    self.graduateGroup.bgSprite.setInteractive();
                    self.undergraduateGroup.bgSprite.setInteractive();
                    self.btnTriangleBackSprite.setInteractive();
                  });
                }, this);
              });
            }, this);
          });
          break;
        case 'region':
          self.step = 'main';
          this.sceneBoot.windowResized = true;
          for(var p in this.prefaceGroup){
            var sprite = this.prefaceGroup[p];
            sprite.visible = false;
          }

          if(window.custom.selected.category == '研究生'){
            this.graduateGroup.systemSlideSprite.setTexture('system_slide_' + window.custom.selected.id);
            this.typeSprite.setTexture('silde_graduate');
            this.graduateGroup.bgSprite.visible = true;
            this.graduateGroup.systemSlideSprite.visible = true;
          } else{
            this.undergraduateGroup.systemSlideSprite.setTexture('system_slide_' + window.custom.selected.id);
            this.typeSprite.setTexture('silde_undergraduate');
            this.undergraduateGroup.bgSprite.visible = true;
            this.undergraduateGroup.systemSlideSprite.visible = true;
          }

          this.graduateGroup.bgSprite.disableInteractive();

          this.typeSprite.visible = true;

          this.sceneBoot.showRegionBg();

          this.setLoadingProgress(0);
          this.startLoadingAnimation();
          
          self.toMainScene();
          break;
        case 'category':
          this.step = 'guide';

          for(var p in this.prefaceGroup){
            var sprite = this.prefaceGroup[p];
            sprite.visible = false;
          }

          for(var i = 0; i < this.pannelGroup.length; i ++){
            this.pannelGroup[i].bgSprite.disableInteractive();
          }

          this.toSystemList(function(){
            var timer1 = self.time.delayedCall(50, function(){
              self.toSelectSystem(window.custom.selected.id, window.custom.selected.name, function(){
                for(var i = 0; i < self.pannelGroup.length; i ++){
                  self.pannelGroup[i].bgSprite.setInteractive();
                }     
              });  
            }, this);
          });
          
          break;
      }

    } else{
      //normal
      this.textGuideAnimation();
      this.enterAnimation();
    }


    // this.input.on('pointerdown', function () {
    //   console.log(2);
    // }, this);
  },

  update: function(){
  },

  resized: function () {
    if(this.step == 'main'){
      if(window.custom.selected.category == '研究生'){
        this.graduateGroup.bgSprite.x = this.scaler.scaleX(this.sidebarWidth + this.sidebarOffset);
        this.graduateGroup.bgSprite.y = this.scaler.scaleY(this.scaler.designRefHeight()/2);
        this.scaler.scaleSpriteByHeight(this.graduateGroup.bgSprite);

        this.graduateGroup.systemSlideSprite.x = this.scaler.scaleX(this.sidebarWidth/2 + this.sidebarOffset);
        this.graduateGroup.systemSlideSprite.y = this.scaler.scaleY(51);
        this.scaler.scaleSpriteByHeight(this.graduateGroup.systemSlideSprite);
      }

      if(window.custom.selected.category == '本科生'){
        this.undergraduateGroup.bgSprite.x = this.scaler.scaleX(this.sidebarWidth + this.sidebarOffset);
        this.undergraduateGroup.bgSprite.y = this.scaler.scaleY(this.scaler.designRefHeight()/2);
        this.scaler.scaleSpriteByHeight(this.undergraduateGroup.bgSprite);

        this.undergraduateGroup.systemSlideSprite.x = this.scaler.scaleX(this.sidebarWidth/2 + this.sidebarOffset);
        this.undergraduateGroup.systemSlideSprite.y = this.scaler.scaleY(51);
        this.scaler.scaleSpriteByHeight(this.undergraduateGroup.systemSlideSprite);
      }

      [this.typeSprite, this.btnStudents, this.btnHall, this.btnChange].map(sprite=>{
        sprite.x = this.scaler.scaleX(sprite.keepData.designX);
        sprite.y = this.scaler.scaleY(sprite.keepData.designY);
        this.scaler.scaleSpriteByHeight(sprite);
      });

      //loading group reize
      for(var p in this.loadingGroup){
        var s = this.loadingGroup[p];
        if(s.keepData && s.keepData.resized == true){
          if(s.keepData.location && s.keepData.location == 'right'){
            s.x = this.scaler.scaleX(this.scaler.designRefWidth() + s.keepData.designX);
          } else if(s.keepData.location && s.keepData.location == 'center-right'){
            s.x = this.scaler.scaleX(this.scaler.designRefWidth()/2 + s.keepData.designX);
          } else{
            s.x = this.scaler.scaleX(s.keepData.designX);
          }
          s.y = this.scaler.scaleY(s.keepData.designY);

          if(s.keepData.fill){
            this.scaler.scaleSpriteFill(s);
          } else{
            this.scaler.scaleSpriteByHeight(s);
          }
          if(s.keepData.width && s.keepData.height){
            s.setDisplaySize(this.scaler.vScale() * s.keepData.width, this.scaler.vScale() * s.keepData.height);
          }

          if(p.indexOf('loadingIcon') >= 0){
            // s.tween.data[0].start = this.scaler.scaleY(s.keepData.designY);
            // s.tween.data[0].end = this.scaler.scaleY(s.keepData.designY - 30);
            // console.log( this.scaler.scaleY(s.keepData.designY) + '...'  + this.scaler.scaleY(s.keepData.designY - 30))
            s.tween.remove();
            var delay = s.index * 100;
            s.tween = this.tweens.add({
              targets: s,
              y: this.scaler.scaleY(s.keepData.designY - 30),
              duration: 500,
              delay: delay,
              ease: 'Linear',
              repeat: -1,
              yoyo: true,
              onComplete: function () {
              },
            });
          }
        }
      }

      return;
    }

    var phaserElements = this.children.list;
    for(var i = 0; i < phaserElements.length; i ++){
      var s = phaserElements[i];
      if(s.keepData && s.keepData.resized == true){
        if(s.keepData.location && s.keepData.location == 'right'){
          s.x = this.scaler.scaleX(this.scaler.designRefWidth() + s.keepData.designX);
        } else if(s.keepData.location && s.keepData.location == 'center-right'){
          s.x = this.scaler.scaleX(this.scaler.designRefWidth()/2 + s.keepData.designX);
        } else{
          s.x = this.scaler.scaleX(s.keepData.designX);
        }
        s.y = this.scaler.scaleY(s.keepData.designY);

        if(s.keepData.fill){
          this.scaler.scaleSpriteFill(s);
        } else{
          this.scaler.scaleSpriteByHeight(s);
        }
        if(s.keepData.width && s.keepData.height){
          s.setDisplaySize(this.scaler.vScale() * s.keepData.width, this.scaler.vScale() * s.keepData.height);
        }
        if(s.keepData.scaleOrig){
          s.scaleX *= s.keepData.scaleOrig;
          s.scaleY *= s.keepData.scaleOrig;
        }
      }
    }
  },

  createPrefaceGroup: function(){
    var self = this;
    var group = {};

    var designX, designY, sprite;

    designX = -132;
    designY = this.scaler.designRefHeight()/2;
    sprite = this.add.image(
      this.scaler.scaleX(this.scaler.designRefWidth() + designX),
      this.scaler.scaleY(designY),
      'pannel_pink'
    );
    sprite.setOrigin(1, 0.5);
    this.scaler.scaleSpriteByHeight(sprite);
    sprite.displayWidth = this.scaler.vScale() * 1118;
    sprite.displayHeight = this.scaler.vScale() * 983;
    sprite.keepData = {
      resized: true,
      location: 'right',
      designX: designX,
      designY: designY,
      width: 1118,
      height: 983
    };
    sprite.setInteractive();
    group.bgSprite = sprite;
    this.enterElements.push(sprite);

    designX = -1034;
    designY = 555;
    sprite = this.add.image(
      this.scaler.scaleX(this.scaler.designRefWidth() + designX),
      this.scaler.scaleY(designY),
      'preface_title'
    );
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSpriteByHeight(sprite);
    sprite.keepData = {
      resized: true,
      location: 'right',
      designX: designX,
      designY: designY
    };
    group.titleSprite = sprite;
    this.enterElements.push(sprite);

    designX = -847;
    designY = 104;
    sprite = this.add.image(
      this.scaler.scaleX(this.scaler.designRefWidth() + designX),
      this.scaler.scaleY(designY),
      'preface_content_cn'
    );
    sprite.setOrigin(0, 0);
    this.scaler.scaleSpriteByHeight(sprite);
    sprite.keepData = {
      resized: true,
      location: 'right',
      designX: designX,
      designY: designY
    };
    group.contentSprite = sprite;
    this.enterElements.push(sprite);
    this.prefaceContentSprite = sprite;

    designX = -731;
    designY = 970;
    sprite = this.add.image(
      this.scaler.scaleX(this.scaler.designRefWidth()  + designX),
      this.scaler.scaleY(designY),
      'btn_next'
    );
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSpriteByHeight(sprite);
    sprite.keepData = {
      resized: true,
      location: 'right',
      designX: designX,
      designY: designY
    };
    group.btnNextSprite = sprite;
    if(this.language == 'cn' || this.prefaceContentPage == 1){
      sprite.visible = false;
    }
    this.enterElements.push(sprite);

    sprite.setInteractive();
    sprite.on("pointerdown", pointer => {
      group.btnNextSprite.alpha = 0.6;
    });
    sprite.on("pointerup", pointer => {
      group.btnNextSprite.alpha = 1.0;
      this.toNextPage();
    });

    designX = -731;
    designY = 970;
    sprite = this.add.image(
      this.scaler.scaleX(this.scaler.designRefWidth()  + designX),
      this.scaler.scaleY(designY),
      'btn_prev'
    );
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSpriteByHeight(sprite);
    sprite.keepData = {
      resized: true,
      location: 'right',
      designX: designX,
      designY: designY
    };
    group.btnPrevSprite = sprite;
    if(this.language == 'cn' || this.prefaceContentPage == 0){
      sprite.visible = false;
    }
    this.enterElements.push(sprite);

    sprite.setInteractive();
    sprite.on("pointerdown", pointer => {
      group.btnPrevSprite.alpha = 0.6;
    });
    sprite.on("pointerup", pointer => {
      group.btnPrevSprite.alpha = 1.0;
      this.toPrevPage();
    });

    designX = -222;
    designY = 973;
    sprite = this.add.image(
      this.scaler.scaleX(this.scaler.designRefWidth()  + designX),
      this.scaler.scaleY(designY),
      'btn_lan_cn'
    );
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSpriteByHeight(sprite);
    sprite.scaleX *= 0.83;
    sprite.scaleY *= 0.83;
    sprite.keepData = {
      resized: true,
      location: 'right',
      designX: designX,
      designY: designY,
      scaleOrig: 0.83
    };
    group.btnLanSprite = sprite;
    this.enterElements.push(sprite);

    sprite.setInteractive();
    sprite.on("pointerdown", pointer => {
      group.btnLanSprite.alpha = 0.6;
    });
    sprite.on("pointerup", pointer => {
      group.btnLanSprite.alpha = 1.0;
      this.toChangeLanPreface();
    });

    designX = -68;
    designY = this.scaler.designRefHeight()/2;
    sprite = this.add.image(
      this.scaler.scaleX(this.scaler.designRefWidth()  + designX),
      this.scaler.scaleY(designY),
      'btn_right'
    );
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSpriteByHeight(sprite);
    sprite.depth = 1;
    sprite.keepData = {
      resized: true,
      location: 'right',
      designX: designX,
      designY: designY
    };
    group.btnRightSprite = sprite;
    this.enterElements.push(sprite);

    [/*group.bgSprite, */group.btnRightSprite].map(sprite=>{
      sprite.setInteractive();
      sprite.on("pointerdown", pointer => {
        group.btnRightSprite.alpha = 0.6;
      });
      sprite.on("pointerup", pointer => {
        group.btnRightSprite.alpha = 1.0;
        this.toSystemList();
      });
    });

    return group;
  },

  createSystemsPannel: function(){
    var self = this;
    var group = [];
    var list = [
      {system: '染织服装艺术设计系', 研究生: false, 本科生: false},
      {system: '陶瓷艺术设计系', 研究生: false, 本科生: false},
      {system: '视觉传达设计系', 研究生: false, 本科生: false},
      {system: '环境艺术设计系', 研究生: false, 本科生: false},
      {system: '工业设计系', 研究生: false, 本科生: false},
      {system: '工艺美术系', 研究生: false, 本科生: false},
      {system: '信息艺术设计系', 研究生: false, 本科生: false},
      {system: '绘画系', 研究生: false, 本科生: false},
      {system: '雕塑系', 研究生: false, 本科生: false},
      {system: '艺术史论系', 研究生: false, 本科生: false},
      {system: '基础教研室', 研究生: false, 本科生: false},
      {system: '全日制科普创意与设计项目艺术硕士', 研究生: false, 本科生: false},
    ];
    var systemList = window.custom.systemList;
    for(var s in systemList){
      var type = systemList[s].label.split('-')[2];
      var sys = systemList[s].label.split('-')[1];

      var same = false;
      var index = -1;
      for(var i = 0; i < list.length; i ++){
        if(list[i].system.indexOf(sys) >= 0){
          same = true;
          index = i;
          break;
        }
      }

      if(index != -1){
        list[index][type] = true;
      }

    }
    this.systemsFormate = list;

    var cols = 3;
    for(var i = 0; i < list.length; i ++){
      (function(i){
        group[i] = {};

        var cellW = 368;
        var cellH = 235;
        var spacing = 16;
        var designX =  - 130 - (cols - (i%cols)) * (cellW + spacing) + cellW/2;
        var designY = 50 + parseInt(i/cols) * (cellH + spacing) + cellH/2;
        var bgSprite = self.add.image(
          self.scaler.scaleX(self.scaler.designRefWidth() + designX),
          self.scaler.scaleY(designY),
          'fliping_0'
        );
        bgSprite.setOrigin(0.5, 0.5);
        bgSprite.keepData = {
          resized: true,
          location: 'right',
          designX: designX,
          designY: designY
        };
        self.scaler.scaleSpriteByHeight(bgSprite);
        group[i].bgSprite = bgSprite;
        // self.allElements.push(bgSprite);

        bgSprite.setInteractive();
        bgSprite.on("pointerdown", pointer => {
          bgSprite.alpha = 0.6;
        });
        bgSprite.on("pointerup", pointer => {
          bgSprite.alpha = 1.0;
          // self.descriptionSpriteTweenParams.x = self.scaler.scaleX(x + width/2);
          // self.descriptionSpriteTweenParams.y = self.scaler.scaleY(y + height/2);

          // self.descriptionSprite.x = self.scaler.scaleX(x);
          // self.descriptionSprite.y = self.scaler.scaleY(y);
          self.toSelectSystem(i, list[i].system);
        });

        var designX = - 130 - (cols - (i%cols)) * (cellW + spacing) + 20;
        var designY = 50 + parseInt(i/cols) * (cellH + spacing) + 15;
        var textSprite = self.add.image(
          self.scaler.scaleX(self.scaler.designRefWidth() + designX),
          self.scaler.scaleY(designY),
          'system_name_' + i
        );
        textSprite.setOrigin(0, 0);
        textSprite.keepData = {
          resized: true,
          location: 'right',
          designX: designX,
          designY: designY
        };
        self.scaler.scaleSpriteByHeight(textSprite);
        group[i].textSprite = textSprite;
        // self.allElements.push(textSprite);
      }(i));
    }

    return group;
  },

  createDescriptionGroup: function(){
    var group = {};

    var designX, designY, sprite;

    designX = -1721/2;
    designY = this.scaler.designRefHeight()/2;
    sprite = this.add.image(
      this.scaler.scaleX(this.scaler.designRefWidth() + designX),
      this.scaler.scaleY(designY),
      'pannel_pink'
    );
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSpriteByHeight(sprite);
    sprite.keepData = {
      resized: true,
      location: 'right',
      scale: sprite.scaleX,
      designX: designX,
      designY: designY
    };
    sprite.visible = false;
    sprite.scaleX *= 0.22;
    sprite.scaleY *= 0.22;
    // this.descriptionSprite.depth = 1;
    // this.descriptionSpriteTweenParams.scale = this.descriptionSprite.scaleX;
    sprite.setInteractive();

    group.bgSprite = sprite;

    designX = -1653;
    designY = 47;
    sprite = this.add.image(
      this.scaler.scaleX(this.scaler.designRefWidth() + designX),
      this.scaler.scaleY(designY),
      'title_en_' + window.custom.selected.id 
    );
    sprite.setOrigin(0, 0);
    this.scaler.scaleSpriteByHeight(sprite);
    sprite.keepData = {
      resized: true,
      location: 'right',
      designX: designX,
      designY: designY
    };
    sprite.alpha = 0;
    group.titleENSprite = sprite;

    designX = -1653;
    designY = 384;
    sprite = this.add.image(
      this.scaler.scaleX(this.scaler.designRefWidth() + designX),
      this.scaler.scaleY(designY),
      'title_cn_' + window.custom.selected.id 
    );
    sprite.setOrigin(0, 0);
    this.scaler.scaleSpriteByHeight(sprite);
    sprite.keepData = {
      resized: true,
      location: 'right',
      designX: designX,
      designY: designY
    };
    sprite.alpha = 0;
    group.titleCNSprite = sprite;

    designX = -1053;
    designY = 387;
    sprite = this.add.image(
      this.scaler.scaleX(this.scaler.designRefWidth() + designX),
      this.scaler.scaleY(designY),
      'content_en_' + window.custom.selected.id 
    );
    sprite.setOrigin(0, 0);
    this.scaler.scaleSpriteByHeight(sprite);
    sprite.keepData = {
      resized: true,
      location: 'right',
      designX: designX,
      designY: designY
    };
    sprite.alpha = 0;
    group.contentSprite = sprite;

    designX = -1602;
    designY = 977;
    sprite = this.add.image(
      this.scaler.scaleX(this.scaler.designRefWidth() + designX),
      this.scaler.scaleY(designY),
      'btn_lan_en'
    );
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSpriteByHeight(sprite);
    sprite.keepData = {
      resized: true,
      location: 'right',
      designX: designX,
      designY: designY
    };
    sprite.setInteractive();
    sprite.on("pointerdown", pointer => {
      group.btnLanSprite.alpha = 0.6;
    });
    sprite.on("pointerup", pointer => {
      group.btnLanSprite.alpha = 1.0;
      this.toChangeLan();
    });
    sprite.alpha = 0;
    group.btnLanSprite = sprite;

    designX = -80;
    designY = this.scaler.designRefHeight()/2;
    sprite = this.add.image(
      this.scaler.scaleX(this.scaler.designRefWidth() + designX),
      this.scaler.scaleY(designY),
      'btn_right'
    );
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSpriteByHeight(sprite);
    sprite.depth = 1;
    sprite.keepData = {
      resized: true,
      location: 'right',
      designX: designX,
      designY: designY
    };
    sprite.alpha = 0;
    group.btnRight = sprite;

    sprite.setInteractive();
    sprite.on("pointerdown", pointer => {
      group.btnRight.alpha = 0.6;
    });
    sprite.on("pointerup", pointer => {
      group.btnRight.alpha = 1.0;
      this.toCategorysPannel();
    });
    group.btnRight.visible = false;


    designX = -80;
    designY = 979;
    sprite = this.add.image(
      this.scaler.scaleX(this.scaler.designRefWidth() +designX),
      this.scaler.scaleY(designY),
      'btn_close'
    );
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSpriteByHeight(sprite);
    sprite.depth = 1;
    sprite.keepData = {
      resized: true,
      location: 'right',
      designX: designX,
      designY: designY
    };
    sprite.alpha = 0;

    sprite.setInteractive();
    sprite.on("pointerdown", pointer => {
      group.btnClose.alpha = 0.6;
    });
    sprite.on("pointerup", pointer => {
      group.btnClose.alpha = 1.0;
      this.toCloseDescription();
    });
    group.btnClose = sprite;
    group.btnClose.visible = false;

    return group;
  },

  createGraduateGroup: function(){
    var group = {};
    var designX, designY, sprite;

    designX = 0;
    designY = this.scaler.designRefHeight()/2;
    sprite = this.add.image(
      this.scaler.scaleX(this.scaler.designRefWidth() + designX),
      this.scaler.scaleY(designY),
      'bg_blue'
    );
    sprite.setOrigin(1, 0.5);
    this.scaler.scaleSpriteFill(sprite);
    sprite.keepData = {
      resized: true,
      location: 'right',
      designX: designX,
      designY: designY,
      fill: true
    };
    group.bgSprite = sprite;
    sprite.visible = false;

    sprite.setInteractive();
    sprite.on("pointerdown", pointer => {
      group.systemSlideSprite.alpha = 0.6;
      group.systemSlideSprite.alpha = 0.6;
    });
    sprite.on("pointerup", pointer => {
      group.systemSlideSprite.alpha = 1.0;
      group.systemSlideSprite.alpha = 1;

      window.custom.selected.category = '研究生';
      this.toSelectCategory();
    });

    designX = -317;
    designY = 73;
    sprite = this.add.image(
      this.scaler.scaleX(this.scaler.designRefWidth() + designX),
      this.scaler.scaleY(designY),
      'text_graduate'
    );
    sprite.setOrigin(1, 0);
    this.scaler.scaleSpriteByHeight(sprite);
    sprite.keepData = {
      resized: true,
      location: 'right',
      designX: designX,
      designY: designY
    };
    group.textSprite = sprite;
    sprite.visible = false;

    designX = -this.sidebarWidth/2;
    designY = 51;
    sprite = this.add.image(
      this.scaler.scaleX(this.scaler.designRefWidth() + designX),
      this.scaler.scaleY(designY),
      'system_slide_' + window.custom.selected.id 
    );
    sprite.setOrigin(0.5, 0);
    this.scaler.scaleSpriteByHeight(sprite);
    sprite.keepData = {
      resized: true,
      location: 'right',
      designX: designX,
      designY: designY
    };
    group.systemSlideSprite = sprite;
    sprite.visible = false;

    designX = -80;
    designY = 964;
    sprite = this.add.image(
      this.scaler.scaleX(this.scaler.designRefWidth() + designX),
      this.scaler.scaleY(designY),
      'btn_right'
    );
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSpriteByHeight(sprite);
    sprite.depth = 1;
    sprite.keepData = {
      resized: true,
      location: 'right',
      designX: designX,
      designY: designY
    };
    sprite.visible = false;
    group.btnRightSprite = sprite;

    return group;
  },

  createUndergraduateGroup: function(){
    var group = {};
    var designX, designY, sprite;

    designX = 0;
    designY = this.scaler.designRefHeight()/2;
    sprite = this.add.image(
      this.scaler.scaleX(this.scaler.designRefWidth()/2 + designX),
      this.scaler.scaleY(designY),
      'bg_pink'
    );
    sprite.setOrigin(1, 0.5);
    this.scaler.scaleSpriteFill(sprite);
    sprite.keepData = {
      resized: true,
      location: 'center-right',
      designX: designX,
      designY: designY,
      fill: true
    };
    group.bgSprite = sprite;
    sprite.visible = false;

    sprite.setInteractive();
    sprite.on("pointerdown", pointer => {
      group.systemSlideSprite.alpha = 0.6;
      group.systemSlideSprite.alpha = 0.6;
    });
    sprite.on("pointerup", pointer => {
      group.systemSlideSprite.alpha = 1;
      group.systemSlideSprite.alpha = 1;

      window.custom.selected.category = '本科生';
      this.toSelectCategory();
    });

    designX = -317;
    designY = 73;
    sprite = this.add.image(
      this.scaler.scaleX(this.scaler.designRefWidth()/2 + designX),
      this.scaler.scaleY(designY),
      'text_undergraduate'
    );
    sprite.setOrigin(1, 0);
    this.scaler.scaleSpriteByHeight(sprite);
    sprite.keepData = {
      resized: true,
      location: 'center-right',
      designX: designX,
      designY: designY
    };
    group.textSprite = sprite;
    sprite.visible = false;

    designX = -this.sidebarWidth/2;
    designY = 51;
    sprite = this.add.image(
      this.scaler.scaleX(this.scaler.designRefWidth()/2 + designX),
      this.scaler.scaleY(designY),
      'system_slide_' + window.custom.selected.id 
    );
    sprite.setOrigin(0.5, 0);
    this.scaler.scaleSpriteByHeight(sprite);
    sprite.keepData = {
      resized: true,
      location: 'center-right',
      designX: designX,
      designY: designY
    };
    group.systemSlideSprite = sprite;
    sprite.visible = false;

    designX = -80;
    designY = 964;
    sprite = this.add.image(
      this.scaler.scaleX(this.scaler.designRefWidth()/2 + designX),
      this.scaler.scaleY(designY),
      'btn_right'
    );
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSpriteByHeight(sprite);
    sprite.depth = 1;
    sprite.keepData = {
      resized: true,
      location: 'center-right',
      designX: designX,
      designY: designY
    };
    sprite.visible = false;
    group.btnRightSprite = sprite;

    return group;
  },

  createLoadingGroup: function(){
    var group = {};
    var designX, designY, sprite;

    designX = 0;
    designY = 0;
    var bgColor = 0xF8DAE7;
    if(window.custom.selected.category == '研究生'){
      bgColor = 0xD5D9EE;
    }
    sprite = this.createColorBg(
      this.scaler.scaleX(designX),
      this.scaler.scaleY(designY),
      bgColor,
      0.9
    );
    sprite.keepData = {
      resized: true,
      fill: true,
      designX: designX,
      designY: designY
    };
    sprite.setInteractive();
    sprite.visible = false;
    group.bgSprite = sprite;

    designX = this.sidebarWidth/2 + this.sidebarOffset;
    designY = 967;
    sprite = this.add.image(
      this.scaler.scaleX(this.scaler.designRefWidth()/2 + designX),
      this.scaler.scaleY(designY),
      'btn_refresh_pink'
    );
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSpriteByHeight(sprite);
    sprite.keepData = {
      resized: true,
      location: 'center-right',
      designX: designX,
      designY: designY
    };
    sprite.visible = false;
    group.btnRefreshSprite = sprite;

    sprite.setInteractive();
    sprite.on("pointerdown", pointer => {
    });
    sprite.on("pointerup", pointer => {
      window.location.reload();

      // var index = this.sceneMain.viewer.UIController.currentRegionIndex;
      // index ++;
      // if(index >= this.sceneMain.viewer.regions.length){
      //   index = 0;
      // }
      // this.sceneMain.viewer.jumpTo(this.sceneMain.viewer.regions[index]);
    });

    var x = [-56 + this.sidebarWidth/2 + this.sidebarOffset, 0 + this.sidebarWidth/2 + this.sidebarOffset, 56 + this.sidebarWidth/2 + this.sidebarOffset];
    for(var i = 0; i < x.length; i ++){
      designX = x[i];
      designY = 513;
      sprite = this.add.image(
        this.scaler.scaleX(this.scaler.designRefWidth()/2 + designX),
        this.scaler.scaleY(designY),
        'loading_pink'
      );
      sprite.setOrigin(0.5, 0.5);
      this.scaler.scaleSpriteByHeight(sprite);
      sprite.index = i;
      sprite.keepData = {
        resized: true,
        location: 'center-right',
        designX: designX,
        designY: designY
      };
      group['loadingIcon' + i] = sprite;
      sprite.visible = false;

      var delay = i * 100;
      sprite.tween = this.tweens.add({
        targets: sprite,
        y: this.scaler.scaleY(designY - 30),
        duration: 500,
        delay: delay,
        ease: 'Linear',
        repeat: -1,
        yoyo: true,
        onComplete: function () {
        },
      });

      // sprite.setInteractive();
      // sprite.on("pointerdown", pointer => {
      // });
      // sprite.on("pointerup", pointer => {
      //   var index = this.sceneMain.viewer.UIController.currentRegionIndex;
      //   index ++;
      //   if(index >= this.sceneMain.viewer.regions.length){
      //     index = 0;
      //   }
      //   this.sceneMain.viewer.jumpTo(this.sceneMain.viewer.regions[index]);
      // });

      // sprite.tween.pause();
    }

    designX = -104 + this.sidebarWidth/2 + this.sidebarOffset;
    designY = 567;
    sprite = this.add.bitmapText(
      this.scaler.scaleX(this.scaler.designRefWidth()/2 + designX),
      this.scaler.scaleY(designY),
      'loadingFontPink',
      '', 24).setOrigin(0, 0.5).setCenterAlign();
    this.scaler.scaleSpriteByHeight(sprite);
    sprite.keepData = {
      resized: true,
      location: 'center-right',
      designX: designX,
      designY: designY
    };
    // sprite.setLetterSpacing(this.scaler.vScale() * 5);
    sprite.setText('L' + 100 + '%');
    group.loadingTextSprite = sprite;
    sprite.visible = false;

    return group;
  },

  toSystemList: function(func_callback){
    var self = this;
    //this.prefaceGroup 移出
    for(var p in this.prefaceGroup){
      var sprite = this.prefaceGroup[p];

      this.tweens.add({
        targets: sprite,
        alpha: 0,
        x: this.scaler.scaleX(sprite.keepData.designX - this.scaler.designRefWidth()),
        duration: this.duration.preface,
        delay: 0,
        ease: 'Linear',
        repeat: 0,
        // yoyo: true,
        onComplete: function () {
          this.targets[0].visible = false;
        },
      });

      if(sprite.keepData.height){
        var targetHeight = this.scaler.designRefHeight();
        var targetWidth = targetHeight * sprite.keepData.width/sprite.keepData.height;

        this.tweens.add({
          targets: sprite,
          displayWidth: this.scaler.vScale() * targetWidth,
          displayHeight: this.scaler.vScale() * targetHeight,
          duration: this.duration.preface/3,
          delay: 0,
          ease: 'Linear',
          repeat: 0,
          // yoyo: true,
          onComplete: function () {
          },
        });
      }
    }

    //pannelGroup 移入
    for(var i = 0; i < this.pannelGroup.length; i ++){
      for(var p in this.pannelGroup[i]){
        var sprite = this.pannelGroup[i][p];
        sprite.visible = true;
        sprite.x = this.scaler.scaleX(this.scaler.designRefWidth() + sprite.keepData.designX) + this.scaler.width;
        this.tweens.add({
          targets: sprite,
          x: this.scaler.scaleX(this.scaler.designRefWidth() + sprite.keepData.designX),
          duration: this.duration.systemListIn,
          delay: 0,
          ease: 'Linear',
          repeat: 0,
          // yoyo: true,
          onComplete: function () {
            // this.targets[0].keepData.resized = true;
          },
        });
      }
    }

    //show button back to preface
    this.btnBackToPrefaceSprite.visible = true;
    this.tweens.add({
      targets: this.btnBackToPrefaceSprite,
      alpha: 1,
      duration: this.duration.systemListIn,
      delay: 0,
      ease: 'Linear',
      repeat: 0,
      // yoyo: true,
      onComplete: function () {
        // this.targets[0].keepData.resized = true;

        //animation complete
        self.sceneBoot.windowResized = true;

        if(func_callback){
          func_callback();
        }
      },
    });

  },

  backToPreface: function(){
    var self = this;
      //this.prefaceGroup 移入
      for(var p in this.prefaceGroup){
       var sprite = this.prefaceGroup[p];

       sprite.x = this.scaler.scaleX(sprite.keepData.designX);
       sprite.visible = true;

       this.tweens.add({
         targets: sprite,
         alpha: 1,
         x: this.scaler.scaleX(this.scaler.designRefWidth() + sprite.keepData.designX),
         duration: this.duration.preface,
         delay: 0,
         ease: 'Linear',
         repeat: 0,
         // yoyo: true,
         onComplete: function () {
          // this.targets[0].keepData.resized = true;
         },
      });

       if(sprite.keepData.height){
        var targetHeight = sprite.keepData.height;
        var targetWidth = sprite.keepData.width;

        this.tweens.add({
          targets: sprite,
          displayWidth: this.scaler.vScale() * targetWidth,
          displayHeight: this.scaler.vScale() * targetHeight,
          duration: this.duration.preface/3,
          delay: 0,
          ease: 'Linear',
          repeat: 0,
          // yoyo: true,
          onComplete: function () {
          },
        });
      }
     }

     //pannelGroup 移出
     for(var i = 0; i < this.pannelGroup.length; i ++){
       for(var p in this.pannelGroup[i]){
         var sprite = this.pannelGroup[i][p];
         this.tweens.add({
           targets: sprite,
           x: this.scaler.scaleX(sprite.keepData.designX + this.scaler.designRefWidth()*2),
           duration: this.duration.systemListIn,
           delay: 0,
           ease: 'Linear',
           repeat: 0,
           // yoyo: true,
           onComplete: function () {
            this.targets[0].visible = false;
            // sprite.visible = false;
           },
         });
       }
     }

     //hide button back to preface
     this.tweens.add({
       targets: this.btnBackToPrefaceSprite,
       alpha: 0,
       duration: this.duration.systemListIn,
       delay: 0,
       ease: 'Linear',
       repeat: 0,
       // yoyo: true,
       onComplete: function () {
        this.targets[0].visible = false;

        //animation complete
        self.sceneBoot.windowResized = true;
       },
     });
  },

  toSelectSystem: function(index, system, func_callback){
    this.flipingTimer.paused = true;

    var self = this;

    window.custom.selected.name = system;
    window.custom.selected.id = index;

    var systemIndex = window.custom.selected.id;

    this.graduateGroup.systemSlideSprite.setTexture('system_slide_' + systemIndex);
    this.undergraduateGroup.systemSlideSprite.setTexture('system_slide_' + systemIndex);

    this.descriptionGroup.btnLanSprite.setTexture('btn_lan_' + this.language);
    this.descriptionGroup.titleENSprite.setTexture('title_en' + '_' + systemIndex);
    this.descriptionGroup.titleCNSprite.setTexture('title_cn' + '_' + systemIndex);
    this.descriptionGroup.contentSprite.setTexture('content_' + this.language + '_' + systemIndex);

    this.btnBackToPrefaceSprite.visible = false;

    //禁止12块板点击
    for(var i = 0; i < this.pannelGroup.length; i ++){
      self.pannelGroup[i].bgSprite.disableInteractive();
    }

    this.selectSystemAnimation(function(){
      //animation complete
      self.sceneBoot.windowResized = true;

      if(func_callback){
        func_callback();
      }
    });

    // var timer = this.time.delayedCall(5000, function(){
    //   self.descriptionSprite.visible = false;
    //   self.btnTriangleSprite.visible = false;
    //   self.setGroupVisible(self.pannelGroup, true);
    // }, this);
  },

  toCloseDescription: function(func_callback){
    this.flipingTimer.paused = false;

    this.descriptionGroup.btnRight.visible = false;
    this.descriptionGroup.btnClose.visible = false;

    var self = this;
    var current = window.custom.selected.id;

    //和进入时相反
    var obj = this.animationSort(current);
    var moveDownIndex = obj.moveUpIndex;
    var moveUpIndex = obj.moveDownIndex;

    for(var i = 0; i < moveUpIndex.length; i ++){
      var index = moveUpIndex[i];
      var bgSprite = this.pannelGroup[index].bgSprite;
      bgSprite.y = this.scaler.scaleY(bgSprite.keepData.designY) + this.scaler.scaleY(this.scaler.designRefHeight());
      bgSprite.visible = true;
      this.tweens.add({
        targets: bgSprite,
        y: this.scaler.scaleY(bgSprite.keepData.designY),
        duration: this.duration.systemListOut,
        delay: 0,
        ease: 'Linear',
        repeat: 0,
        // yoyo: true,
        onComplete: function () {
        },
      });

      var textSprite = this.pannelGroup[index].textSprite;
      textSprite.y = this.scaler.scaleY(textSprite.keepData.designY) + this.scaler.scaleY(this.scaler.designRefHeight());
      textSprite.visible = true;
      this.tweens.add({
        targets: textSprite,
        y: this.scaler.scaleY(textSprite.keepData.designY),
        duration: this.duration.systemListOut,
        delay: 0,
        ease: 'Linear',
        repeat: 0,
        // yoyo: true,
        onComplete: function () {
        },
      });
    }

    for(var i = 0; i < moveDownIndex.length; i ++){
      var index = moveDownIndex[i];
      var bgSprite = this.pannelGroup[index].bgSprite;
      bgSprite.y = this.scaler.scaleY(bgSprite.keepData.designY) - this.scaler.scaleY(this.scaler.designRefHeight());
      bgSprite.visible = true;
      this.tweens.add({
        targets: bgSprite,
        y: this.scaler.scaleY(bgSprite.keepData.designY),
        duration: this.duration.systemListOut,
        delay: 0,
        ease: 'Linear',
        repeat: 0,
        // yoyo: true,
        onComplete: function () {
        },
      });

      var textSprite = this.pannelGroup[index].textSprite;
      textSprite.y = this.scaler.scaleY(textSprite.keepData.designY) - this.scaler.scaleY(this.scaler.designRefHeight());
      textSprite.visible = true;
      this.tweens.add({
        targets: textSprite,
        y: this.scaler.scaleY(textSprite.keepData.designY),
        duration: this.duration.systemListOut,
        delay: 0,
        ease: 'Linear',
        repeat: 0,
        // yoyo: true,
        onComplete: function () {
        },
      });
    }

    var targetX = this.pannelGroup[current].bgSprite.keepData.designX;
    var targetY = this.pannelGroup[current].bgSprite.keepData.designY;
    this.tweens.add({
      targets: this.descriptionGroup.bgSprite,
      // scale: this.descriptionGroup.bgSprite.keepData.scale * 0.22,
      displayWidth: this.scaler.vScale() * 1721 * 0.22,
      displayHeight: this.scaler.vScale() * 1080 * 0.22,
      x: this.scaler.scaleX(this.scaler.designRefWidth() + targetX),
      y: this.scaler.scaleX(targetY),
      duration: this.duration.descriptionIn,
      delay: 0,
      ease: 'Linear',
      repeat: 0,
      // yoyo: true,
      onComplete: function () {
        self.btnBackToPrefaceSprite.alpha = 1;
        self.btnBackToPrefaceSprite.visible = true;
        self.descriptionGroup.bgSprite.visible = false;
        self.pannelGroup[current].bgSprite.alpha = 1;
      },
    });

    for(var p in this.descriptionGroup){
      if(p == 'bgSprite'){
        continue;
      }
      var sprite = this.descriptionGroup[p];
      sprite.visible = false;
      sprite.alpha = 0;
    }

    this.pannelGroup[current].textSprite.x = this.scaler.scaleX(this.scaler.designRefWidth() + this.pannelGroup[current].textSprite.keepData.designX);
    this.pannelGroup[current].textSprite.y = this.scaler.scaleY(this.pannelGroup[current].textSprite.keepData.designY);
    this.pannelGroup[current].textSprite.alpha = 0;
    this.pannelGroup[current].textSprite.visible = true;
    this.pannelGroup[current].bgSprite.visible = true;
    this.tweens.add({
      targets: this.pannelGroup[current].textSprite,
      alpha: 1,
      duration: 10,//this.duration.descriptionIn,
      delay: this.duration.descriptionIn,
      ease: 'Linear',
      repeat: 0,
      // yoyo: true,
      onComplete: function () {
        //animation complete
        self.sceneBoot.windowResized = true;

        //12块板允许点击
        for(var i = 0; i < self.pannelGroup.length; i ++){
          self.pannelGroup[i].bgSprite.setInteractive();
        }

        if(func_callback){
          func_callback();
        }
      },
    });

    for(var i = 0; i < this.textGuideGroup.length; i ++){
      this.tweens.add({
        targets: this.textGuideGroup[i],
        alpha: 1,
        duration: this.duration.systemListOut,
        delay: 0,
        ease: 'Linear',
        repeat: 0,
        // yoyo: true,
        onComplete: function () {
        },
      });
    }

    this.tweens.add({
      targets: this.btnBack,
      alpha: 1,
      duration: this.duration.systemListOut,
      delay: 0,
      ease: 'Linear',
      repeat: 0,
      // yoyo: true,
      onComplete: function () {
      },
    });
  },

  toCategorysPannel: function(){
    var self = this;

    this.showCategoryAnimation(function(){
      //animation complete
      self.sceneBoot.windowResized = true;

      //只有研究生的系，自动跳到展厅页面
      var obj = self.judgeType();
      if(obj.graduate == true && obj.undergraduate == false){
        window.custom.selected.category = '研究生';

        self.toSelectCategory();
      }
    });
  },

  toSelectCategory: function(){
    var self = this;

    this.graduateGroup.bgSprite.disableInteractive();
    this.undergraduateGroup.bgSprite.disableInteractive();
    this.btnTriangleBackSprite.disableInteractive();

    this.graduateGroup.btnRightSprite.visible = false;
    this.undergraduateGroup.btnRightSprite.visible = false;
    this.btnTriangleBackSprite.visible = false;

    var systemList = window.custom.systemList;
    var selectedSystem = window.custom.selected.name;
    var category = window.custom.selected.category;

    for(var s in systemList){
      if(systemList[s].label.indexOf(selectedSystem) >= 0 && systemList[s].label.indexOf(category) >= 0){
        this.latest = systemList[s].latest;
        break;
      }
    }

    this.setLoadingProgress(0);
    this.startLoadingAnimation();
    this.hideCategoryAnimation(function(){
      self.toMainScene();
    });
  },

  backToDescription: function(enableClick, func_callback){
    var self = this;
    this.btnTriangleBackSprite.visible = false;

    var graduate = false;
    var undergraduate = false;
    var selectedSystem = window.custom.selected.name;
     
    for(var i = 0; i < this.systemsFormate.length; i ++){
      if(this.systemsFormate[i].system.indexOf(selectedSystem) >= 0){
        if(this.systemsFormate[i].研究生 == true){
          graduate = true;
        }
        if(this.systemsFormate[i].本科生 == true){
          undergraduate = true;
        }
      }
    }

    var completeNum = 0;
    for(var p in this.descriptionGroup){
      var sprite = this.descriptionGroup[p];
      sprite.x = this.scaler.scaleX(sprite.keepData.designX);
      var targetX = this.scaler.scaleX(this.scaler.designRefWidth() + sprite.keepData.designX);

      if(p != 'btnClose' && p != 'btnRight' && p != 'btnLanSprite'){
        sprite.visible = true;
      }

      if(p == 'bgSprite'){
        sprite.displayWidth = this.scaler.vScale() * 1721;
        sprite.displayHeight = this.scaler.vScale() * 1080;
      }
      this.tweens.add({
        targets: sprite,
        x: targetX,
        duration: this.duration.descriptionOut,
        delay: 0,
        ease: 'Linear',
        repeat: 0,
        // yoyo: true,
        onComplete: function () {
          // this.targets[0].visible = false;

          completeNum ++;
          if(completeNum >= Object.keys(self.descriptionGroup).length){
            self.descriptionGroup.titleENSprite.alpha = 1;
            self.descriptionGroup.titleCNSprite.alpha = 1;
            self.descriptionGroup.contentSprite.alpha = 1;

            if(enableClick == true){
              self.descriptionGroup.btnClose.visible = true;
              self.descriptionGroup.btnRight.visible = true;
              self.descriptionGroup.btnLanSprite.visible = true;
            }

            if(func_callback){
              func_callback();
            }
            //animation complete
            self.sceneBoot.windowResized = true;
          }
        },
      });

      if(p == 'btnClose'|| p == 'btnRight' || p == 'btnLanSprite'){
        this.tweens.add({
          targets: sprite,
          alpha: 1,
          duration: this.duration.descriptionOut,
          delay: 0,
          ease: 'Linear',
          repeat: 0,
          // yoyo: true,
          onComplete: function () {

          },
        });
      }
    }

    for(var p in this.graduateGroup){
      var sprite = this.graduateGroup[p];
      this.tweens.add({
        targets: sprite,
        x: this.scaler.scaleX(sprite.keepData.designX + this.scaler.designRefWidth()*2),
        duration: this.duration.category,
        delay: 0,
        ease: 'Linear',
        repeat: 0,
        // yoyo: true,
        onComplete: function () {
          this.targets[0].visible = false;
        },
      });
    }

    for(var p in this.undergraduateGroup){
      var sprite = this.undergraduateGroup[p];
      this.tweens.add({
        targets: sprite,
        x: this.scaler.scaleX(sprite.keepData.designX + this.scaler.designRefWidth()/2 + this.scaler.designRefWidth()),
        duration: this.duration.category,
        delay: 0,
        ease: 'Linear',
        repeat: 0,
        // yoyo: true,
        onComplete: function () {
          this.targets[0].visible = false;
        },
      });
    }
  },

  toChangeLan: function(){
    if(this.language == 'en'){
      this.language = 'cn';
    } else{
      this.language = 'en';
    }

    this.descriptionGroup.btnLanSprite.setTexture('btn_lan_' + this.language);
    this.descriptionGroup.titleENSprite.setTexture('title_en_' + window.custom.selected.id);
    this.descriptionGroup.titleCNSprite.setTexture('title_cn_' + window.custom.selected.id);
    this.descriptionGroup.contentSprite.setTexture('content_' + this.language + '_' + window.custom.selected.id);
  },

  tochangeType: function(){
    var self = this;

    this.delCookies();

    if(window.custom.selected.category == '研究生'){
      window.custom.selected.category = '本科生';
    } else{
      window.custom.selected.category = '研究生';
    }

    [this.btnStudents, this.btnHall, this.btnChange].map(sprite=>{
      sprite.visible = false;
    });

    this.graduateGroup.bgSprite.disableInteractive();
    this.undergraduateGroup.bgSprite.disableInteractive();
    this.btnTriangleBackSprite.disableInteractive();

    this.step = 'guide';
    this.sceneBoot.windowResized = true;
    this.categoryBackAnimation(false, function(){
      self.sceneMain.scene.setVisible(false);
      self.sceneMain.updating = false;

      self.stopLoadingAnimation();

      self.toSelectCategory();
    });

  },

  toChangeLanPreface: function(){
    var contentSource = '';
    if(this.language == 'en'){
      this.language = 'cn';
      contentSource = 'preface_content_cn';

      this.prefaceGroup.btnNextSprite.visible = false;
      this.prefaceGroup.btnPrevSprite.visible = false;
    } else{
      this.language = 'en';
      contentSource = 'preface_content_en_' + this.prefaceContentPage;

      if(this.prefaceContentPage == 0){
        this.prefaceGroup.btnNextSprite.visible = true;
      }
      if(this.prefaceContentPage == 1){
        this.prefaceGroup.btnPrevSprite.visible = true;
      }
    }

    this.prefaceGroup.btnLanSprite.setTexture('btn_lan_' + this.language);
    this.prefaceGroup.contentSprite.setTexture(contentSource);
  },

  toNextPage: function(){
    this.prefaceContentPage = 1;
    var contentSource = 'preface_content_en_' + this.prefaceContentPage;
    this.prefaceGroup.contentSprite.setTexture(contentSource);
    this.prefaceGroup.btnNextSprite.visible = false;
    this.prefaceGroup.btnPrevSprite.visible = true;
  },

  
  toPrevPage: function(){
    this.prefaceContentPage = 0;
    var contentSource = 'preface_content_en_' + this.prefaceContentPage;
    this.prefaceGroup.contentSprite.setTexture(contentSource);
    this.prefaceGroup.btnNextSprite.visible = true;
    this.prefaceGroup.btnPrevSprite.visible = false;
  },

  toMainScene: function(){
    this.btnTriangleBackSprite.visible = false;
    var self = this;
    $.ajax({
      url: self.latest,
      type: "GET",
      async: true,
      success: function(data) {
        console.log('GET IMAGES SUCCESS!');
        let regionsRaw = data.regions;
        window.publicPath = data.publicPath;
        window.theme = data.theme;
        // self.getSystem(data.label);

        utils.setCookie('selected', encodeURIComponent(JSON.stringify(window.custom.selected)));
        utils.setCookie('locator', regionsRaw[0].meta.locator);

        self.scene.launch('sceneMain', {
          regions: regionsRaw,
          theme: window.theme,
          multiple: 'multiple'
        });
      }, error : function(response) {
        console.log("GET IMAGES FAILED!");
        console.log(JSON.stringify(response));
      }
    });
  },

  showCategoryAnimation: function(func_callback){
    var self = this;

    var graduate = false;
    var undergraduate = false;
    var selectedSystem = window.custom.selected.name;
    for(var i = 0; i < this.systemsFormate.length; i ++){
      if(this.systemsFormate[i].system.indexOf(selectedSystem) >= 0){
        if(this.systemsFormate[i].研究生 == true){
          graduate = true;
        }
        if(this.systemsFormate[i].本科生 == true){
          undergraduate = true;
        }
      }
    }

    var completeNum = 0;
    for(var p in this.descriptionGroup){
      var sprite = this.descriptionGroup[p];
      var targetX = sprite.x - this.scaler.scaleX(this.scaler.designRefWidth());
      this.tweens.add({
        targets: sprite,
        x: targetX,
        duration: this.duration.descriptionOut,
        delay: 0,
        ease: 'Linear',
        repeat: 0,
        // yoyo: true,
        onComplete: function () {
          this.targets[0].visible = false;

          completeNum ++;
          if(completeNum >= Object.keys(self.descriptionGroup).length){
            if(func_callback){
              func_callback();
            }
          }
        },
      });

      if(p == 'btnClose'|| p == 'btnRight' || p == 'btnLanSprite'){
        sprite.visible = false;
        // this.tweens.add({
        //   targets: sprite,
        //   alpha: 0,
        //   duration: this.duration.descriptionOut/2,
        //   delay: 0,
        //   ease: 'Linear',
        //   repeat: 0,
        //   // yoyo: true,
        //   onComplete: function () {

        //   },
        // });
      }
    }

    if(graduate){
      for(var p in this.graduateGroup){
        var sprite = this.graduateGroup[p];
        sprite.visible = true;
        sprite.x = this.scaler.scaleX(sprite.keepData.designX + this.scaler.designRefWidth() * 2);


        this.tweens.add({
          targets: sprite,
          x: this.scaler.scaleX(sprite.keepData.designX + this.scaler.designRefWidth()),
          duration: this.duration.category,
          delay: 0,
          ease: 'Linear',
          repeat: 0,
          // yoyo: true,
          onComplete: function () {
          },
        });

        if(p == 'btnRightSprite'){
          sprite.alpha = 0;
          this.tweens.add({
            targets: sprite,
            alpha: 1,
            duration: this.duration.category/2,
            delay: this.duration.category,
            ease: 'Linear',
            repeat: 0,
            // yoyo: true,
            onComplete: function () {
            },
          });
        }
      }

    }

    if(undergraduate){
      for(var p in this.undergraduateGroup){
        var sprite = this.undergraduateGroup[p];
        sprite.visible = true;
        sprite.x = this.scaler.scaleX(sprite.keepData.designX + this.scaler.designRefWidth() * 2);

        this.tweens.add({
          targets: sprite,
          x: this.scaler.scaleX(sprite.keepData.designX + this.scaler.designRefWidth()/2),
          duration: this.duration.category,
          delay: 0,
          ease: 'Linear',
          repeat: 0,
          // yoyo: true,
          onComplete: function () {
          },
        });

        if(p == 'btnRightSprite'){
          sprite.alpha = 0;
          this.tweens.add({
            targets: sprite,
            alpha: 1,
            duration: this.duration.category/2,
            delay: this.duration.category,
            ease: 'Linear',
            repeat: 0,
            // yoyo: true,
            onComplete: function () {
            },
          });
        }
      }
    }


    this.btnTriangleBackSprite.alpha = 0;
    this.btnTriangleBackSprite.visible = true;
    this.tweens.add({
      targets: this.btnTriangleBackSprite,
      alpha: 1,
      duration: this.duration.category/2,
      delay: this.duration.category,
      ease: 'Linear',
      repeat: 0,
      // yoyo: true,
      onComplete: function () {
      },
    });

  },

  hideCategoryAnimation: function(func_callback){
    var sceneBoot = this.scene.get('sceneBoot');
    sceneBoot.showRegionBg();

    var self = this;
    var completeNum = 0;
    for(var p in this.graduateGroup){
      var sprite = this.graduateGroup[p];
      this.tweens.add({
        targets: sprite,
        x: this.scaler.scaleX(sprite.keepData.designX + this.sidebarWidth + this.sidebarOffset),
        duration: this.duration.category,
        delay: 0,
        ease: 'Linear',
        repeat: 0,
        // yoyo: true,
        onComplete: function () {
          if(p == 'textSprite'){
            this.targets[0].visible = false;
          }
          if(window.custom.selected.category == '本科生'){
            this.targets[0].visible = false;
            self.typeSprite.setTexture('silde_undergraduate');
          } else{
            self.typeSprite.setTexture('silde_graduate');
          }

          self.typeSprite.visible = true;

          completeNum ++;
          if(completeNum >= Object.keys(self.graduateGroup).length){
            self.step = 'main';
            if(func_callback){
              func_callback();
            }
          }
        },
      });
    }

    var targetX = this.sidebarWidth + this.sidebarOffset;
    if(window.custom.selected.category == '研究生'){
      targetX = 0;
    }
    for(var p in this.undergraduateGroup){
      var sprite = this.undergraduateGroup[p];
      this.tweens.add({
        targets: sprite,
        x: this.scaler.scaleX(sprite.keepData.designX + targetX),
        duration: this.duration.category,
        delay: 0,
        ease: 'Linear',
        repeat: 0,
        // yoyo: true,
        onComplete: function () {
          if(p == 'textSprite'){
            this.targets[0].visible = false;
          }
          if(window.custom.selected.category == '研究生'){
            this.targets[0].visible = false;
          }
        },
      });
    }

  },

  categoryBackAnimation: function(enableClick, func_callback){
    var self = this;

    var graduate = false;
    var undergraduate = false;
    var selectedSystem = window.custom.selected.name;
    for(var i = 0; i < this.systemsFormate.length; i ++){
      if(this.systemsFormate[i].system.indexOf(selectedSystem) >= 0){
        if(this.systemsFormate[i].研究生 == true){
          graduate = true;
        }
        if(this.systemsFormate[i].本科生 == true){
          undergraduate = true;
        }
      }
    }

    var completeNum = 0;
    if(graduate){
      for(var p in this.graduateGroup){
        var sprite = this.graduateGroup[p];
        sprite.x = this.scaler.scaleX(sprite.keepData.designX + this.sidebarWidth + this.sidebarOffset);

        sprite.y = this.scaler.scaleY(sprite.keepData.designY);
        if(sprite.keepData.fill){
          this.scaler.scaleSpriteFill(sprite);
        } else{
          this.scaler.scaleSpriteByHeight(sprite);
        }

        sprite.visible = true;
        if(enableClick == false && p == 'btnRightSprite'){
          sprite.visible = false;
        }

        sprite.keepData.resized = false;
        this.tweens.add({
          targets: sprite,
          x: this.scaler.scaleX(sprite.keepData.designX + this.scaler.designRefWidth()),
          duration: this.duration.category,
          delay: 0,
          ease: 'Linear',
          repeat: 0,
          // yoyo: true,
          onComplete: function () {
            this.targets[0].keepData.resized = true;

            completeNum ++;
            if(completeNum >= Object.keys(self.graduateGroup).length){
              if(func_callback){
                func_callback();
              }
            }
          },
        });
      }

    }

    if(undergraduate){
      for(var p in this.undergraduateGroup){
        var sprite = this.undergraduateGroup[p];
        sprite.x = this.scaler.scaleX(sprite.keepData.designX + this.sidebarWidth + this.sidebarOffset);
        sprite.y = this.scaler.scaleY(sprite.keepData.designY);
        if(sprite.keepData.fill){
          this.scaler.scaleSpriteFill(sprite);
        } else{
          this.scaler.scaleSpriteByHeight(sprite);
        }
        sprite.visible = true;

        if(enableClick == false && p == 'btnRightSprite'){
          sprite.visible = false;
        }

        sprite.keepData.resized = false;
        this.tweens.add({
          targets: sprite,
          x: this.scaler.scaleX(sprite.keepData.designX + this.scaler.designRefWidth()/2),
          duration: this.duration.category,
          delay: 0,
          ease: 'Linear',
          repeat: 0,
          // yoyo: true,
          onComplete: function () {
            this.targets[0].keepData.resized = true;
          },
        });
      }
    }

    this.typeSprite.visible = false;
  },

  createColorBg: function(x, y, color, alpha){
    var rect = this.add.rectangle(
      x,
      y,
      this.scaler.width,
      this.scaler.height
      , color, alpha);
    rect.setOrigin(0, 0);
    this.scaler.scaleSpriteFill(rect);
    return rect;
  },

  // fetchRegions: function() {
  //   var self = this;
  //   let jsonData = this.galleriesMaps[window.location.origin] || this.galleriesMaps['default'];
  //   $.ajax({
  //     //url: 'http://exhibition-staging.ad.onekind.com.cn/galleries',
  //     url: jsonData,
  //     type: "GET",
  //     async: true,
  //     success: function(data) {
  //       console.log('GET IMAGES SUCCESS!');
  //       // console.log(data);
  //       self.systemList = (typeof data === 'string') ? JSON.parse(data) : data;
  //       // self.addSysControls();

  //       // let hash = self.getUrlParam('hash');
  //       // self.latest = self.systemList[hash].latest;
  //     }, error : function(response) {
  //       console.log("GET IMAGES FAILED!");
  //       console.log(JSON.stringify(response));
  //     }
  //   });
  // },

  // addSysControls: function(){
  //   var self = this;
  //   var gui = new dat.gui.GUI();
  //   var controls = {};
  //   for(var s in this.systemList){
  //     (function(s){
  //       var name = self.systemList[s].label.split('-')[2] + '-' + self.systemList[s].label.split('-')[1];
  //       controls[name] = function() {
  //         window.location.href = '?theme=' + self.getUrlParam('theme') + '&hash=' + s;
  //       };
  //     }(s));
  //   }
  //   gui.remember(controls);
  //   for(var s in controls){
  //     gui.add(controls, s);
  //   }
  //   gui.close();
  // },

  getUrlParam: function(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]);
    return null;
  },

  setGroupVisible: function(group, visible){
    var children = group.getChildren();
    for(var i = 0; i < children.length; i ++){
      children[i].visible = visible;
    }
  },


  hideAllElements: function(){
    var phaserElements = this.children.list;
    for(var i = 0; i < phaserElements.length; i ++){
      var s = phaserElements[i];
      s.visible = false;
    }
  },

  enterAnimation: function(func_callback){
    this.sceneBoot.colourBarShowAnimation();

    var cam = this.cameras.main;
    cam.zoom = 0.25;
    cam.zoomTo(1, this.duration.enter);

    for(var p in this.prefaceGroup){
      var sprite = this.prefaceGroup[p];

      sprite.alpha = 0;
      this.tweens.add({
        targets: sprite,
        alpha: 1,
        duration: this.duration.enter,
        delay: 0,
        ease: 'Linear',
        repeat: 0,
        // yoyo: true,
        onComplete: function () {
        },
      });
    }
  //   var self = this;
  //   var completeNum = 0;
  //   for(var i = 0; i < this.enterElements.length; i ++){
  //     var s = this.enterElements[i];
  //     var targetX = this.scaler.scaleX(s.keepData.designX);
  //     if(s.keepData.location && s.keepData.location == 'right'){
  //       s.x = this.scaler.scaleX(this.scaler.designRefWidth() * 2 + s.keepData.designX);
  //       targetX = this.scaler.scaleX(this.scaler.designRefWidth() + s.keepData.designX);
  //     } else{
  //       s.x = this.scaler.scaleX(this.scaler.designRefWidth() + s.keepData.designX);
  //     }
  //     this.tweens.add({
  //       targets: s,
  //       x: targetX,
  //       duration: this.duration.enter,
  //       delay: 0,
  //       ease: 'Linear',
  //       repeat: 0,
  //       // yoyo: true,
  //       onComplete: function () {
  //         completeNum ++;
  //         if(completeNum >= self.enterElements.length){
  //           if(func_callback){
  //             func_callback();
  //           }
  //         }
  //       },
  //     });
  //   }
  },

  textGuideAnimation: function(func_callback){
    var self = this;
    var completeNum = 0;
    for(var i = 0; i < this.textGuideGroup.length; i ++){
      var sprite = this.textGuideGroup[i];
      var delay = 200*i;
      this.tweens.add({
        targets: sprite,
        alpha: 1,
        duration: this.duration.enter,
        delay: delay,
        ease: 'Linear',
        repeat: 0,
        // yoyo: true,
        onComplete: function () {
        },
      });
    }

    this.tweens.add({
      targets: this.btnBack,
      alpha: 1,
      duration: this.duration.enter,
      delay: delay,
      ease: 'Linear',
      repeat: 0,
      // yoyo: true,
      onComplete: function () {
      },
    });
  },

  selectSystemAnimation: function(func_callback){
    var self = this;
    //this.pannelGroup
    var current = window.custom.selected.id;

    var obj = this.animationSort(current);
    var moveUpIndex = obj.moveUpIndex;
    var moveDownIndex = obj.moveDownIndex;

    this.moveUpIndex = moveUpIndex;
    this.moveDownIndex = moveDownIndex;

    this.pannelGroup[current].bgSprite.alpha = 0;
    this.pannelGroup[current].textSprite.alpha = 0;

    this.pannelGroup[current].bgSprite.visible = false;
    this.pannelGroup[current].textSprite.visible = false;

    //向上移出
    for(var i = 0; i < moveUpIndex.length; i ++){
      var index = moveUpIndex[i];
      var bgSprite = this.pannelGroup[index].bgSprite;
      var targetY = bgSprite.y - this.scaler.scaleY(this.scaler.designRefHeight());
      this.tweens.add({
        targets: bgSprite,
        y: targetY,
        duration: this.duration.systemListOut,
        delay: 0,
        ease: 'Linear',
        repeat: 0,
        // yoyo: true,
        onComplete: function () {
          this.targets[0].visible = false;
        },
      });

      var textSprite = this.pannelGroup[index].textSprite;
      var targetY = textSprite.y - this.scaler.scaleY(this.scaler.designRefHeight());
      this.tweens.add({
        targets: textSprite,
        y: targetY,
        duration: this.duration.systemListOut,
        delay: 0,
        ease: 'Linear',
        repeat: 0,
        // yoyo: true,
        onComplete: function () {
          this.targets[0].visible = false;
        },
      });
    }
    //向下移出
    for(var i = 0; i < moveDownIndex.length; i ++){
      var index = moveDownIndex[i];
      var bgSprite = this.pannelGroup[index].bgSprite;
      var targetY = bgSprite.y + this.scaler.scaleY(this.scaler.designRefHeight());
      this.tweens.add({
        targets: bgSprite,
        y: targetY,
        duration: this.duration.systemListOut,
        delay: 0,
        ease: 'Linear',
        repeat: 0,
        // yoyo: true,
        onComplete: function () {
          this.targets[0].visible = false;
        },
      });

      var textSprite = this.pannelGroup[index].textSprite;
      var targetY = textSprite.y + this.scaler.scaleY(this.scaler.designRefHeight());
      this.tweens.add({
        targets: textSprite,
        y: targetY,
        duration: this.duration.systemListOut,
        delay: 0,
        ease: 'Linear',
        repeat: 0,
        // yoyo: true,
        onComplete: function () {
          this.targets[0].visible = false;
        },
      });
    }


    //显示系前言墙
    this.descriptionGroup.bgSprite.x = this.pannelGroup[current].bgSprite.x;
    this.descriptionGroup.bgSprite.y = this.pannelGroup[current].bgSprite.y;
    this.descriptionGroup.bgSprite.scaleX = this.descriptionGroup.bgSprite.keepData.scale*0.22;
    this.descriptionGroup.bgSprite.scaleY = this.descriptionGroup.bgSprite.keepData.scale*0.22;
    this.descriptionGroup.bgSprite.visible = true;
    this.tweens.add({
      targets: this.descriptionGroup.bgSprite,
      x: this.scaler.scaleX(this.scaler.designRefWidth() - 1721/2),
      y: this.scaler.scaleY(this.scaler.designRefHeight()/2),
      // scale: this.descriptionGroup.bgSprite.keepData.scale,
      displayWidth: this.scaler.vScale() * 1721,
      displayHeight: this.scaler.vScale() * 1080,
      duration: this.duration.systemListOut,
      delay: 0,
      ease: 'Linear',
      repeat: 0,
      // yoyo: true,
      onComplete: function () {
      },
    });


    var completeNum = 0;
    for(var p in this.descriptionGroup){
      if(p == 'bgSprite'){
        completeNum ++;
        continue;
      }
      var sprite = this.descriptionGroup[p];
      sprite.visible = true;
      sprite.alpha = 0;
      this.tweens.add({
        targets: sprite,
        alpha: 1,
        duration: this.duration.systemListOut,
        delay: this.duration.systemListOut,
        ease: 'Linear',
        repeat: 0,
        // yoyo: true,
        onComplete: function () {
          completeNum ++;
          if(completeNum >= Object.keys(self.descriptionGroup).length){
            if(func_callback){
              func_callback();
            }
          }
        },
      });
    }

    //隐藏左侧文字
    for(var i = 0; i < this.textGuideGroup.length; i ++){
      this.tweens.add({
        targets: this.textGuideGroup[i],
        alpha: 0,
        duration: this.duration.systemListOut,
        delay: 0,
        ease: 'Linear',
        repeat: 0,
        // yoyo: true,
        onComplete: function () {
        },
      });
    }

    this.tweens.add({
      targets: this.btnBack,
      alpha: 0,
      duration: this.duration.systemListOut,
      delay: 0,
      ease: 'Linear',
      repeat: 0,
      // yoyo: true,
      onComplete: function () {
      },
    });
  },


  animationSort: function(index){
    var moveUpIndex = [], moveDownIndex = [];
    switch(index){
      case 0:
        moveUpIndex = [1, 4, 7, 10];
        moveDownIndex = [3, 6, 9, 2, 5, 8, 11];
        break;
      case 1:
        moveUpIndex = [0, 3, 6, 9, 2, 5, 8, 11];
        moveDownIndex = [4, 7, 10];
        break;
      case 2:
        moveUpIndex = [1, 4, 7, 10];
        moveDownIndex = [0, 3, 6, 9, 5, 8, 11];
        break;
      case 3:
        moveUpIndex = [0, 1, 4, 7, 10];
        moveDownIndex = [6, 9, 2, 5, 8, 11];
        break;
      case 4:
        moveUpIndex = [0, 3, 6, 9, 1, 2, 5, 8, 11];
        moveDownIndex = [7, 10];
        break;
      case 5:
        moveUpIndex = [1, 4, 7, 10, 2];
        moveDownIndex = [0, 3, 6, 9, 8, 11];
        break;
      case 6:
        moveUpIndex = [0, 3, 2, 5, 8, 11];
        moveDownIndex = [9, 1, 4, 7, 10];
        break;
      case 7:
        moveUpIndex = [1, 4];
        moveDownIndex = [0, 3, 6, 9, 10, 2, 5, 8, 11];
        break;
      case 8:
        moveUpIndex = [0, 3, 6, 9, 2, 5];
        moveDownIndex = [1, 4, 7, 10, 11];
        break;
      case 9:
        moveUpIndex = [0, 3, 6, 2, 5, 8, 11];
        moveDownIndex = [1, 4, 7, 10];
        break;
      case 10:
        moveUpIndex = [1, 4, 7];
        moveDownIndex = [0, 3, 6, 9, 2, 5, 8, 11];
        break;
      case 11:
        moveUpIndex = [0, 3, 6, 9, 2, 5, 8];
        moveDownIndex = [1, 4, 7, 10];
        break;
    }

    return {moveUpIndex: moveUpIndex, moveDownIndex: moveDownIndex};
  },

  flipingAnimation: function(){
    var self = this;
    for(var i = 0; i < self.pannelGroup.length; i ++){
      (function(i){
        var bgSprite = self.pannelGroup[i].bgSprite;
        var index = -1;
        var step = self.randomNum(2, 6);
        var totalFrame = 32/step;
        var delay = 1000 / totalFrame;

        var timedEvent = self.time.addEvent({
          delay: delay,
          callback: function(){
            index += step;
            if(index >= 32){
              timedEvent.remove(false);
            } else{
              bgSprite.setTexture('fliping_' + index);
            }

          },
          callbackScope: this,
          loop: true
        });
      }(i));
    }
  },

  showSlidebar: function() {
    this.btnStudents.visible = true;
    this.btnStudents.alpha = 0;
    this.tweens.add({
      targets: this.btnStudents,
      alpha: 1,
      duration: this.duration.sidebarButtons,
      delay: 0,
      ease: 'Linear',
      repeat: 0,
      // yoyo: true,
      onComplete: function () {
      },
    });

    this.btnHall.visible = true;
    this.btnHall.alpha = 0;
    this.tweens.add({
      targets: this.btnHall,
      alpha: 1,
      duration: this.duration.sidebarButtons,
      delay: 0,
      ease: 'Linear',
      repeat: 0,
      // yoyo: true,
      onComplete: function () {
      },
    });


    var obj = this.judgeType();
    var undergraduate = obj.undergraduate;
    var targetAlpha = 1;
    if(window.custom.selected.category == '研究生'){
      this.btnChange.setTexture('btn_change_blue');
    }
    if(window.custom.selected.category == '本科生'){
      this.btnChange.setTexture('btn_change_pink');
    }

    if(undergraduate == false){
      this.btnChange.disableInteractive();
      targetAlpha = 0.5;
    }
    if(undergraduate == true){
      this.btnChange.setInteractive();
    }
    this.btnChange.visible = true;
    this.btnChange.alpha = 0;
    this.tweens.add({
      targets: this.btnChange,
      alpha: targetAlpha,
      duration: this.duration.sidebarButtons,
      delay: 0,
      ease: 'Linear',
      repeat: 0,
      // yoyo: true,
      onComplete: function () {
      },
    });
  },

  startLoadingAnimation: function(){
    for(var p in this.loadingGroup){
      var sprite = this.loadingGroup[p];
      if(p.indexOf('loadingIcon') >= 0){
        if(window.custom.selected.category == '研究生'){
          sprite.setTexture('loading_blue');
        } else{
          sprite.setTexture('loading_pink');
        }

        sprite.tween.resume();
      }
    }

    var bgColor = 0xF8DAE7;
    var font = 'loadingFontPink';
    var refreshSource = 'btn_refresh_pink';
    if(window.custom.selected.category == '研究生'){
      bgColor = 0xD5D9EE;
      font = 'loadingFontBlue';
      refreshSource = 'btn_refresh_blue';
    }
    this.loadingGroup.bgSprite.fillColor = bgColor;
    this.loadingGroup.loadingTextSprite.setFont(font);

    this.loadingGroup.btnRefreshSprite.setTexture(refreshSource);

    for(var p in this.loadingGroup){
      var sprite = this.loadingGroup[p];
      sprite.visible = true;
    }
  },

  setLoadingProgress: function(progress){
    this.loadingGroup.loadingTextSprite.setText('L' + progress + '%');
  },

  stopLoadingAnimation: function(){
    if(this.params.noTip != true && this.sceneMain){
      this.sceneMain.firstRegionLoaded = true;
    }
    
    for(var p in this.loadingGroup){
      var sprite = this.loadingGroup[p];
      if(p.indexOf('loadingIcon') >= 0){
        sprite.tween.pause();
      }

      sprite.visible = false;
    }
  },

  randomNum: function(min, max){
    return Math.floor(Math.random()*(max-min+1)+min);
  },

  judgeType: function(){
    var graduate = false;
    var undergraduate = false;
    var selectedSystem = window.custom.selected.name;
    for(var i = 0; i < this.systemsFormate.length; i ++){
      if(this.systemsFormate[i].system.indexOf(selectedSystem) >= 0){
        if(this.systemsFormate[i].研究生 == true){
          graduate = true;
        }
        if(this.systemsFormate[i].本科生 == true){
          undergraduate = true;
        }
      }
    }

    return {graduate: graduate, undergraduate: undergraduate};
  },

  delCookies: function(){
    utils.delCookie('selected');
    utils.delCookie('locator');
  }

});
export default SceneGuide;
