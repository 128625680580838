var EventEmitter = require('events').EventEmitter;

import emptyImg from '../images/empty.png';
import pointImg from '../images/point2.png';

function DragSprite (scene, scaler) {
  this.scene = scene;
  this.scaler = scaler;
}

DragSprite.prototype.__proto__ = EventEmitter.prototype;

DragSprite.prototype.preload = function () {
  this.scene.load.image('empty', emptyImg);
  // this.scene.load.image('emptyRed', require("../images/demo/empty_red.png"));
  this.scene.load.image('point', pointImg);
};

DragSprite.prototype.create = function () {
  let dragSprite = this.scene.add.image(0, 0, 'empty');
  dragSprite.setInteractive({ draggable: true });
  dragSprite.setOrigin(0, 0);
  dragSprite.alpha = 0.5;
  dragSprite.keepData = { x: 0};

  let pointSprite = this.scene.add.image(
    this.scaler.scaleX(-100),
    this.scaler.scaleY(-100),
    'point'
  );
  pointSprite.setOrigin(0.5, 0.5);
  this.scaler.scaleSprite(pointSprite);
  pointSprite.scaleX *= 0.3;
  pointSprite.scaleY *= 0.3;
  pointSprite.alpha = 0.3;
  pointSprite.visible = false;
  pointSprite.depth = 100;

  this.dragSprite = dragSprite;
  this.pointSprite = pointSprite;

  dragSprite.on('dragstart', function (pointer, dragX, dragY) {
    //console.log(`drag start.. ${pointer.x} ${pointer.y}`);
    this.pointSprite.visible = true;
    this.pointSprite.x = pointer.x;
    this.pointSprite.y = pointer.y;
    this.dragSprite.keepData.x = this.dragSprite.x;
    this.emit('dragstart');
  }, this);

  dragSprite.on('drag', function (pointer, dragX, dragY) {
    //console.log(dragX);
    this.pointSprite.x = pointer.x;
    this.pointSprite.y = pointer.y;

    // check whether out area
    this.emit('drag', {offset: dragX - this.dragSprite.keepData.x});
  }, this);

  dragSprite.on('dragend', function (pointer, dragX, dragY) {
    //console.log('drag end');
    this.pointSprite.visible = false;
    this.emit('dragend');
    //if (this.onDragEnd)
    //this.onDragEnd();
  }, this);

  dragSprite.on('wheel', function (pointer, deltaX, deltaY, deltaZ) {
    this.emit('wheel', {offset: deltaY * 0.4});
  }, this);


};


DragSprite.prototype.resize = function (width, height) {
  this.dragSprite.setScale(width, height);
};


export default DragSprite;