"use strict";

let GC = {
  resolution: 1080,

  dedicatedWidth: 6900 * (3 / 6) ,

  columnWidth: 6900 * 1,

  regionWidth: 6900 * 2,

  layerSpeeds: [4.665, 2.100, 1.353, 1.000],

  layerWidths: [ 6900, 3104, 2000, 1478 ],

  UNLOAD: 'unload',
  LOADING: 'loading',
  LOADED: 'loaded',

  DESTROYED: 'destroyed',
  CREATED: 'created'

};


export default GC;

//console.log(GC);
