'use strict';

import scaler from "./scaler";
import GC from "./const";

import bgWhiteImg from '../images/bg_wh.png';
import bgImg from '../images/bg.png';
import colorBarImg from '../images/colour_bar.png';

import textMobileImg from '../images/countdown/text_mobile.png';
import orientationImg from '../images/icon_orientation.png';
import orientationTipTextImg from '../images/orientation_tip_text.png';
import btnCloseImg from '../images/btn_close.png';
// import orientationSmallImg from '../images/countdown/icon_orientation.png';

var SceneBoot = new Phaser.Class({

  Extends: Phaser.Scene,

  initialize:

  function SceneBoot ()
  {
    Phaser.Scene.call(this, { key: 'sceneBoot', active: true });

    this.currentScene = 'sceneBoot';

    this.output = [
      { name: '染织服装艺术设计系', hash: '1c5b' },
      { name: '陶瓷艺术设计系', hash: 'e399' },
      { name: '视觉传达设计系', hash: 'ea23' },
      { name: '环境艺术设计系', hash: 'd5df' },
      { name: '工业设计系', hash: 'acf0' },
      { name: '工艺美术系', hash: '8d2b' },
      { name: '信息艺术设计系', hash: 'c871' },
      { name: '绘画系', hash: 'a8e0' },
      { name: '雕塑系', hash: 'f18c' },
      { name: '艺术史论系', hash: '9bc6' },
      { name: '基础教研室', hash: 'ead8' },
      { name: '全日制科普创意与设计项目艺术硕士', hash: '2eaf' },
    ];
  },

  init: function(params){
    this.scaler = scaler;
    this.windowResized = false;
    this.bgSprite;
    this.textMobile;
 
    this.width = document.getElementById('phaser-wrapper').clientWidth * window.devicePixelRatio;
    this.height = document.getElementById('phaser-wrapper').clientHeight * window.devicePixelRatio;
    this.recalculateSize();

    this.sceneEnter = this.scene.get('sceneEnter');
    this.sceneCountdown = this.scene.get('sceneCountdown');
    this.sceneGuide = this.scene.get('sceneGuide');
    this.sceneMain = this.scene.get('sceneMain');

    this.scaleMode = 'full';

    this.screenIsRightDirection = true;

    //竖屏
    this._designRefWidth = 1080;
    this._designRefHeight = 1920;

    this.getRegionDate = false;
  },

  preload: function ()
  {
    this.load.image('colour_bar', colorBarImg);

    this.load.image('bg_light', bgWhiteImg);
    this.load.image('bg_dark', bgImg);

    this.load.image('text_mobile', textMobileImg);
    this.load.image('orientation', orientationImg);
    this.load.image('orientation_tip_text', orientationTipTextImg);
    this.load.image('btn_close', btnCloseImg);
    // this.load.image('orientation_small', orientationSmallImg);
  },

  create: function ()
  {
    var sprite, designX, designY;

    var sprite = this.add.image(0, 0, 'bg_drak');
    sprite.setOrigin(0, 0);
    this.scaler.scaleSpriteFill(sprite);
    sprite.keepData = {
      resized: true
    };
    this.bgSprite = sprite;
    this.bgSprite.visible = false;
    
    sprite = this.add.tileSprite(0, 0, 0, 0, 'colour_bar');
    sprite.setOrigin(0, 1);
    this.colourBarSprite = sprite;

    this.colourBarSprite.alpha = 0;
    this.tweens.add({
      targets: this.colourBarSprite,
      alpha: 1,
      duration: 1500,
      delay: 0,
      ease: 'Linear',
      repeat: 0,
      // yoyo: true,
      onComplete: function () {
      },
    });

    designX = this._designRefWidth/2;
    designY = this._designRefHeight/2;
    sprite = this.add.image(
      this.scaleX(designX),
      this.scaleY(designY),
      'text_mobile');
    sprite.setOrigin(0.5, 0.5);
    sprite.visible = false;
    this.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      designX: designX,
      designY: designY
    };
    this.textMobile = sprite;

    designX = 314;
    designY = 818;
    sprite = this.add.image(
      this.scaleX(designX),
      this.scaleY(designY),
      'orientation');
    sprite.setOrigin(0.5, 0.5);
    sprite.visible = false;
    this.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      designX: designX,
      designY: designY
    };
    this.orientationSprite = sprite;
    sprite.setInteractive();
    sprite.on("pointerdown", pointer => {
      this.orientationSprite.alpha = 0.6;
    });
    sprite.on("pointerup", pointer => {
      this.orientationSprite.alpha = 1.0;

      this.openTipDialog();
    });

    this.orientationTipDialogGroup = this.createOrientationTipDialog();
    this.closeOrientationTipDialog();

    this.windowResized = true;

    this.scale.on('resize',function resize(gameSize, baseSize, displaySize) {
      // if(this.scene.isActive('sceneEnter')){
      //   this.recalculateSize2();
      // } else{
      //   this.recalculateSize();
      // }
      if(this.scaleMode == 'full'){
        this.recalculateSize2();
      } else{
        this.recalculateSize();
      }
      
      
      this.windowResized = true;
    }, this);    

    
    
  },

  update: function(){
    if (this.windowResized) {
      this.scale.resize(this.width, this.height);
      this.resized();
      this.windowResized = false;
    }

    this.colourBarSprite.tilePositionX += this.scaler.vScale()*0.7;

    if(this.getRegionDate == false && JSON.stringify(window.custom.systemList) !== '{}'){
      this.getRegionDate = true;
      var rid = utils.getUrlParam('rid');
      var locator = utils.getUrlParam('locator');

      var sid = utils.getUrlParam('sid');
      this.toAnalysisParams(rid, locator, sid);
    }
  },

  recalculateSize2: function() {
    this.width = document.getElementById('phaser-wrapper').clientWidth * window.devicePixelRatio;
    this.height = document.getElementById('phaser-wrapper').clientHeight * window.devicePixelRatio;
  
    // // resize the canvas
    let canvas = document.getElementById('phaser-wrapper').getElementsByTagName('canvas')[0];
    if (canvas) {
      canvas.width = this.width;
      canvas.height = this.height;
    }
  
    // let designRefWidth = GC.resolution * width/height;
    // let designRefHeight = GC.resolution;
  
    if(this.width >= this.height){
      this.scaler.config(this.width, this.height, 1920, 1080);
    } else{
      this.scaler.config(this.width, this.height, 1080, 1920);
    }
    
  },

  recalculateSize: function() {
    this.width = document.getElementById('phaser-wrapper').clientWidth * window.devicePixelRatio;
    this.height = document.getElementById('phaser-wrapper').clientHeight * window.devicePixelRatio;

    // // resize the canvas
    let canvas = document.getElementById('phaser-wrapper').getElementsByTagName('canvas')[0];
    if (canvas) {
      canvas.width = this.width;
      canvas.height = this.height;
    }

    let designRefWidth = GC.resolution * this.width/this.height;
    let designRefHeight = GC.resolution;

    this.scaler.config(this.width, this.height, designRefWidth, designRefHeight);
  },

  resized: function () {
    if(this.scaler.width >= this.scaler.height){
      this.textMobile.visible = false;
      this.orientationSprite.visible = false;

      if(this.currentScene != 'sceneBoot'){
        this.scene.get(this.currentScene).scene.setVisible(true);
      }

      if(this.currentScene == 'sceneMain'){
        this.sceneGuide.scene.setVisible(true);
      }

      if(this.currentScene == 'sceneCountdown'){
        
        if(this.sceneCountdown.isVIP == true){
          this.sceneCountdown.scene.setVisible(true);
        } else{
          if(this.sceneCountdown.startPage){
            this.sceneCountdown.startPage.showAllElements();
          }
        }
      }

      this.scene.sendToBack('sceneBoot');
    } else{
      this.closeOrientationTipDialog();
      this.textMobile.visible = true;
      if(this.currentScene != 'sceneBoot' && this.currentScene != 'sceneCountdown'){
        this.orientationSprite.visible = true;  
      }

      if(this.sceneEnter){
        this.sceneEnter.scene.setVisible(false);
      }
      if(this.sceneCountdown){
        if(this.sceneCountdown.isVIP == true){
          this.sceneCountdown.scene.setVisible(false);
          this.orientationSprite.visible = true;
        } else{
          if(this.sceneCountdown.startPage){
            this.sceneCountdown.startPage.hideAllElements();
          }
        }
        
      }
      if(this.sceneGuide){
        this.sceneGuide.scene.setVisible(false);
      }
      if(this.sceneMain){
        this.sceneMain.scene.setVisible(false);
      }

      this.hideRegionBg();

      if(this.currentScene != 'sceneBoot' && this.currentScene != 'sceneCountdown'){
        this.scene.bringToTop('sceneBoot');
      }
    }
    
    this.colourBarSprite.setPosition(
      0, 
      this.scaler.scaleY(this.scaler.designRefHeight())
    );
    this.scaler.scaleBgSprite(this.colourBarSprite);

    this.bgSprite.setPosition(
      0, 
      0
    );
    this.scaler.scaleSpriteFill(this.bgSprite);

    this.textMobile.setPosition(
      this.scaleX(this._designRefWidth/2), 
      this.scaleY(this._designRefHeight/2)
    );
    this.scaleSprite(this.textMobile);

    this.orientationSprite.setPosition(
      this.scaleX(this.orientationSprite.keepData.designX), 
      this.scaleY(this.orientationSprite.keepData.designY)
    );
    this.scaleSprite(this.orientationSprite);

    var children = this.orientationTipDialogGroup.getChildren();
    for(var i = 0; i < children.length; i ++){
      children[i].visible = false;
      if(children[i].keepData.resized && children[i].keepData.resized == true){
        children[i].x = this.scaleX(children[i].keepData.designX);
        children[i].y = this.scaleX(children[i].keepData.designY);
        this.scaleSprite(children[i]);
      }
    }

    if(this.scene.isActive('sceneCountdown')){
      this.sceneCountdown.resized();
    }
    if(this.scene.isActive('sceneEnter')){
      this.sceneEnter.resized();
    }
    if(this.scene.isActive('sceneGuide')){
      this.sceneGuide.resized();
    }
    if(this.scene.isActive('sceneMain')){
      this.sceneMain.resized();
    }
  },

  createOrientationTipDialog: function(){
    var group = this.add.group();

    var designX, designY, sprite;

    designX = this._designRefWidth/2;
    designY = this._designRefHeight/2;
    sprite = this.add.rectangle(
      this.scaleX(designX),
      this.scaleY(designY),
      891,
      430,
      0xC30080, 0.9);
    // sprite.setOrigin(0.5, 0.5);
    this.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      designX: designX,
      designY: designY
    };
    group.add(sprite);
    sprite.depth = 1;

    sprite = this.add.image(
      this.scaleX(designX),
      this.scaleY(designY),
      'orientation_tip_text'
    );
    sprite.setOrigin(0.5, 0.5);
    this.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      designX: designX,
      designY: designY
    };
    group.add(sprite);
    sprite.depth = 1;

    designX = 907;
    designY = 850;
    sprite = this.add.image(
      this.scaleX(designX),
      this.scaleY(designY),
      'btn_close'
    );
    sprite.setOrigin(0.5, 0.5);
    this.scaleSprite(sprite);
    group.add(sprite);
    sprite.depth = 1;
    sprite.keepData = {
      resized: true,
      designX: designX,
      designY: designY
    };
    var btnCloseSprite = sprite;

    sprite.setInteractive();
    sprite.on("pointerdown", pointer => {
      btnCloseSprite.alpha = 0.6;
    });
    sprite.on("pointerup", pointer => {
      btnCloseSprite.alpha = 1.0;
      this.closeOrientationTipDialog();
    });

    return group;
  },

  getUrlParam: function(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]);
    return null;
  },

  showRegionBg: function(){
    var lightIndexs = [0, 4, 7, 10, 11];
    var theme = 'dark';
    if(lightIndexs.indexOf(this.sceneGuide.systemIndex) >= 0){
      theme = 'light';
    }
    this.bgSprite.setTexture('bg_' + theme);
    this.bgSprite.visible = true;
    this.colourBarSprite.visible = false;
  },

  hideRegionBg: function(){
    this.bgSprite.visible = false;
    this.colourBarSprite.visible = true;
  },

  hideColourBar: function(){
    this.colourBarSprite.alpha = 0;
  },

  colourBarShowAnimation: function(){
    this.colourBarSprite.alpha = 0;
    this.tweens.add({
      targets: this.colourBarSprite,
      alpha: 1,
      duration: 800,
      delay: 300,
      ease: 'Linear',
      repeat: 0,
      // yoyo: true,
      onComplete: function () {
      },
    });
  },

  scaleSprite: function(sprite) {
    sprite.setScale(this.hScale(), this.vScale());
  },

  scaleSpriteByHeight: function(sprite) {
    sprite.setScale(this.vScale(), this.vScale());
  },

  scaleX: function (designX, align) {
    let alignment = (align || 'middle').toLowerCase();

    if (alignment == 'top') {
        return designX * this.hScale();
    } else if (alignment == 'bottom') {
        return this.offsetPos().x * 2 + designX * this.hScale();
    } else {
        return this.offsetPos().x + designX * this.hScale();
    }
  },

  scaleY: function(designY, align, offset) {
    let alignment = (align || 'middle').toLowerCase();
    offset = offset || 0;
    if(alignment == 'top') {
        return this.offsetPos().y * offset + designY * this.vScale();
    } else if (alignment == 'bottom') {
        return this.offsetPos().y * 2 + designY * this.vScale();
    } else {
        return this.offsetPos().y + designY * this.vScale();
    }
  },

  hScale: function() {
    // return(this.game.canvas.width / this._designRefWidth);
    if(this.offsetPos().x != 0)
        return (this.height / this._designRefHeight);
    else
        return (this.width / this._designRefWidth);
  },

  vScale: function() {
    // return(this.game.canvas.height / this._designRefHeight);
    if(this.offsetPos().y != 0)
        return (this.width / this._designRefWidth);
    else
        return (this.height / this._designRefHeight);
  },

  offsetPos: function() {
    var offsetX = 0;
    var offsetY = 0;
    var ws = this.width / this._designRefWidth;
    var hs = this.height / this._designRefHeight;
    if(ws < hs){
        offsetY = (this.height - this._designRefHeight * this.width/this._designRefWidth)/2;
    }else{
        offsetX = (this.width - this._designRefWidth * this.height/this._designRefHeight)/2;
    }
    return {x: offsetX, y: offsetY};
  },

  openTipDialog: function(){
    var children = this.orientationTipDialogGroup.getChildren();
    for(var i = 0; i < children.length; i ++){
      children[i].visible = true;
    }
  },

  closeOrientationTipDialog: function(){
    var children = this.orientationTipDialogGroup.getChildren();
    for(var i = 0; i < children.length; i ++){
      children[i].visible = false;
    }
  },

  toAnalysisParams: function(rid, locator, sid){
    var self = this;
    if(rid && locator){//个人展厅
      var latest = window.custom.systemList[rid].latest;
      var name = window.custom.systemList[rid].label.split('-')[1];
      var category = window.custom.systemList[rid].label.split('-')[2];
      var id = 0;
  
      for(var i = 0; i < this.output.length; i ++){
        if(this.output[i].name.indexOf(name) >= 0){
          id = i;
          break;
        }
      }
      window.custom.selected = {id: id, name: name, category: category};
      window.custom.locator = locator;
  
      //get region data
      $.ajax({
        url: latest,
        type: "GET",
        async: true,
        success: function(data) {
          console.log('GET IMAGES SUCCESS!');
          let regionsRaw = data.regions;
          window.publicPath = data.publicPath;
          window.theme = data.theme;
  
          var texture;
          var index;
          for(var i = 0; i < regionsRaw.length; i ++){
            if(regionsRaw[i].meta.locator == locator){
              index = i;
              texture = regionsRaw[i].meta.name.texture;
            }
          }
  
          if(texture && index != undefined){
            var personal = {
              texture: texture,
              path: data.publicPath + regionsRaw[index].preloadMaterials.images[texture]
            };
            self.toNextPage({personal: personal, latest: latest});
          } else{
            //
            self.toNextPage();
          }
          
        }, error : function(response) {
          console.log("GET IMAGES FAILED!");
          console.log(JSON.stringify(response));
  
          self.toNextPage();
        }
      });
    } else if(sid){//系长廊  
      var name = '';
      var id = 0;
      for(var i = 0; i < this.output.length; i ++){
        if(this.output[i].hash == sid){
          id = i;
          name = this.output[i].name;
          break;
        }
      }
      window.custom.selected = {id: id, name: name, category: ''};
      
      var system = {
        id: id,
        name: name
      };
      self.toNextPage({system: system});
    } else{//正常模式
      this.toNextPage();
    }
    
  },

  toNextPage: function(params){
    if(this.sceneEnter){
      if(params){
        this.scene.launch('sceneEnter', params);
      } else{
        this.scene.launch('sceneEnter');
      }
    } else{
      // this.orientationSprite.setTexture('orientation_small');
      // this.orientationSprite.x = this.scaleX(510);
      // this.orientationSprite.y = this.scaleY(898);

      // this.orientationSprite.keepData.designX = 510;
      // this.orientationSprite.keepData.designY = 898;

      this.scene.launch('sceneCountdown'/*, {scaler: this.scaler}*/);
    }
  }



});
export default SceneBoot;