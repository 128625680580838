'use strict';

import viewer from "./viewer";

import iconSlideImg from '../images/icon_slide.png';

var SceneMain = new Phaser.Class({

  Extends: Phaser.Scene,

  initialize:

  function SceneMain ()
  {
    Phaser.Scene.call(this, { key: 'sceneMain', active: false });

    this.controlParams = {
      远景速度: 1,
      中景速度: 1.353,
      近景速度: 2.100,
      超景速度: 4.665,
      动画帧数: 6,
      查看作品集: function() {
        // if(viewer.regions[0] && viewer.regions[0].portfolio){
        //   viewer.regions[0].portfolio.open('item__1');
        // }
      }
    };

  },

  init: function(params){
    var sceneBoot = this.scene.get('sceneBoot');
    this.scaler = sceneBoot.scaler;

    this.hasData = false;
    if(params.regions){
      this.hasData = true;
    }

    this.viewer = viewer;
    if(this.hasData){
      this.viewer.init(
        params.regions, 
        params.theme, 
        [this.controlParams.超景速度, this.controlParams.近景速度, this.controlParams.中景速度, this.controlParams.远景速度], 
        params.multiple
      );
    }
    this.created = false;

    sceneBoot.currentScene = 'sceneMain';
    this.updating = true;

    sceneBoot.windowResized = true;

    this.firstRegionLoaded = false;
    this.tipPlayed = false;
  },

  preload: function ()
  {
    console.log('SceneMain preload..');
    if(this.hasData){
      this.viewer.setScene(this);
      this.viewer.preload();
    }

    this.load.image('icon_slide', iconSlideImg);
  },

  create: function ()
  {
    var designX, designY, sprite;

    if(this.hasData){
      this.viewer.create();
      this.viewer.windowResized = true;
      this.created = true;
    }

    designX = -390;
    designY = this.scaler.designRefHeight()/2;
    sprite = this.add.image(
      this.scaler.scaleX(this.scaler.designRefWidth() + designX),
      this.scaler.scaleY(designY),
      'icon_slide'
    );
    sprite.setOrigin(0.5, 0.5);
    sprite.keepData = {
      resized: true,
      location: 'right',
      designX: designX,
      designY: designY
    };
    sprite.depth = 70000;
    this.scaler.scaleSpriteByHeight(sprite);
    sprite.visible = false;
    this.iconSlideSprite = sprite;

    this.input.on('pointerdown', function () {
      this.iconSlideSprite.visible = false;
    }, this);
  },

  update: function(){
    if(this.created && this.updating){
      this.viewer.update(this);
    }

    if(this.tipPlayed == false && this.firstRegionLoaded == true){
      this.tipPlayed = true;
      this.tipAnimation();
    }
  },

  resized: function(){
    this.viewer.resized();

    [this.iconSlideSprite].map(sprite => {
      if(sprite.keepData && sprite.keepData.resized == true){
        if(sprite.keepData.location && sprite.keepData.location == 'right'){
          sprite.x = this.scaler.scaleX(this.scaler.designRefWidth() + sprite.keepData.designX);
        } else if(sprite.keepData.location && sprite.keepData.location == 'center-right'){
          sprite.x = this.scaler.scaleX(this.scaler.designRefWidth()/2 + sprite.keepData.designX);
        } else{
          sprite.x = this.scaler.scaleX(sprite.keepData.designX);
        }
        sprite.y = this.scaler.scaleY(sprite.keepData.designY);
        this.scaler.scaleSpriteByHeight(sprite);
      }
    });
    
  },

  tipAnimation: function(){
    this.iconSlideSprite.visible = true;
    this.iconSlideSprite.alpha = 0;
    this.tweens.add({
      targets: this.iconSlideSprite,
      alpha: 1,
      duration: 800,
      delay: 0,
      ease: 'Linear',
      repeat: -1,
      yoyo: true,
      onComplete: function () {
      },
    });
  }

});
export default SceneMain;