import gallery18df from "../dataset/galleries-18df.txt";
import shareIconImg from '../images/share_icon.jpg';
// var vConsole = new VConsole();

var galleriesMaps = {
  // 'https://exhibition.ad.tsinghua.edu.cn': gallery18df,
  // 'http://exhibition-origin.ad.onekind.com.cn': galleryd9db,
  // 'http://exhibition-staging.ad.onekind.com.cn': gallery39c6,
  'default': gallery18df
};

function Utils() {
}

Utils.prototype.fetchRegions = function() {
  let jsonData = galleriesMaps[window.location.origin] || galleriesMaps['default'];
  $.ajax({
    //url: 'http://exhibition-staging.ad.onekind.com.cn/galleries',
    url: jsonData,
    type: "GET",
    async: true,
    success: function(data) {
      console.log('GET IMAGES SUCCESS!');
      // console.log(data);
      window.custom.systemList = (typeof data === 'string') ? JSON.parse(data) : data;

    }, error : function(response) {
      console.log("GET IMAGES FAILED!");
      console.log(JSON.stringify(response));
    }
  });
};

Utils.prototype.setCookie = function(name,value)
{
  var Days = 30;
  var exp = new Date();
  exp.setTime(exp.getTime() + Days*24*60*60*1000);
  // console.log(name + '..' + decodeURIComponent(value));
  document.cookie = name + "="+ escape (value) + ";expires=" + exp.toGMTString();
};

Utils.prototype.getCookie = function(name)
{
  var arr,reg=new RegExp("(^| )"+name+"=([^;]*)(;|$)");

  if(arr = document.cookie.match(reg))

    return unescape(arr[2]);
  else
    return null;
};

Utils.prototype.delCookie = function(name)
{
  var exp = new Date();
  exp.setTime(exp.getTime() - 1);
  var cval = this.getCookie(name);
  if(cval != null)
    document.cookie= name + "="+cval+";expires="+exp.toGMTString();
};

Utils.prototype.isIphone = function(){
  return /iphone/gi.test(navigator.userAgent);
};

Utils.prototype.isIphoneXORIphone11Wechat = function(){
  if (typeof window !== 'undefined' && window) {
    return /iphone/gi.test(navigator.userAgent) && /micromessenger/gi.test(window.navigator.userAgent) && window.screen.height >= 812;
  }
  return false;
};

Utils.prototype.isWechat = function(){
  if (typeof window !== 'undefined' && window) {
    return /micromessenger/gi.test(window.navigator.userAgent);
  }
  return false;
};

Utils.prototype.getUrlParam = function(name) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
  var r = window.location.search.substr(1).match(reg);
  if (r != null) return unescape(r[2]);
  return null;
};

Utils.prototype.wechatShare = function(){
  let url = window.location.href;

  let configUrl = url.replace(/exhibition.+/,'wx.ad.onekind.com.cn/wxconfig?url=' + document.location.href);

  let sharedPicUrl = window.location.origin + '/' + shareIconImg;
  let sharedPageUrl = 'https://exhibition.ad.tsinghua.edu.cn';


  // console.log(configUrl);
  // console.log(sharedPageUrl);
  // console.log(sharedPicUrl);

  $.getJSON(configUrl,function(config){
    console.log('wechat share..');
    console.log(config);
    // alert (data.result);
    //alert(JSON.stringify(config));

    config.debug = false;
    config.jsApiList = ['onMenuShareTimeline',
                        'onMenuShareAppMessage'];


    // console.log('config....');
    // console.log(JSON.stringify(config));
    window.wx = wx;
    wx.config(config);

    wx.ready(function(){
      wx.onMenuShareTimeline({
        title: '清华大学美术学院线上毕业作品展',
        link: sharedPageUrl,
        imgUrl: sharedPicUrl,
        success: function () {
        },
        cancel: function () {
        },
        complete: function(res){
        }
      });

      wx.onMenuShareAppMessage({
        title: '清华大学美术学院2020届线上毕业作品展',
        link: sharedPageUrl,
        imgUrl: sharedPicUrl,
        desc: '艺术之美，飞在云端，长在心里！',
        type: '',
        dataUrl: '',
        success: function () {
        },
        cancel: function () {
        },
        complete: function(res){
        }
      });

    });

    wx.error(function(res){
      console.log('error!!');
      console.log(res);
    });
  });
};

export default Utils;