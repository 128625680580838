var EventEmitter = require('events').EventEmitter;

var currentVideo;

function VideoSprite(scene, scaler) {
  this.scene = scene;
  this.scaler = scaler;
  this.dragMaxOffset;
  this.videoLoaded = false;
};

VideoSprite.prototype.__proto__ = EventEmitter.prototype;

VideoSprite.prototype.setAssets = function (assets) {
  this.assets = assets;
};

VideoSprite.prototype.setPosition = function (position) {
  this.position = position;
};

VideoSprite.prototype.setGroup = function (group) {
  this.group = group;
};

VideoSprite.prototype.setDepth = function (depth) {
  this.depth = depth;
};

VideoSprite.prototype.setVideoUrl = function (url) {
  this.videoUrl = url;
};

VideoSprite.prototype.getDragSprite = function () {
  return this.sprite;
};

VideoSprite.prototype.preload = function () {
  //this.scene.load.image('empty', require("../images/demo/empty.png"));
};


VideoSprite.prototype.create = function () {
  let designX = this.position.x;
  let designY = this.position.y;
  let texture;

  //video frame sprite
  let sprite = this.scene.add.image(
    this.scaler.scaleX(designX),
    this.scaler.scaleY(designY),
    'empty'
  );
  sprite.setOrigin(0.5, 0.5);
  sprite.keepData = {
    resized: true,
    designX: designX,
    designY: designY,
    x: sprite.x
  };
  this.group.add(sprite);
  sprite.depth = this.depth;

  //sprite.setInteractive({ draggable: true });
  sprite.on("pointerdown", pointer => {
    this.videoSprite.keepData.pressed = true;
    //console.log(pointer);
    //console.log(this);
  }, this);

  sprite.on("pointerup", pointer => {
    //console.log(pointer);
    //console.log(this);
    (this.dragMaxOffset <= 5) && this.videoUrl && (this.togglePlay());
  }, this);

  // set drag events
  sprite.on('drag', function (pointer, dragX, dragY) {
    //console.log("draggin" + dragX);
    let offset = dragX - this.frameSprite.keepData.x;
    (Math.abs(offset) > this.dragMaxOffset) && (this.dragMaxOffset = Math.abs(offset));
    this.emit('drag', {offset: offset});
  }, this);

  sprite.on('dragstart', function (pointer, dragX, dragY) {
    //console.log(`drag start.. ${pointer.x} ${pointer.y}`);
    this.dragMaxOffset = 0;
    this.frameSprite.keepData.x = this.frameSprite.x;
    this.emit('dragstart');
  }, this);

  sprite.on('dragend', function (pointer, dragX, dragY) {
    this.emit('dragend');
  }, this);

  this.frameSprite = sprite;

  // video sprite
  sprite = this.scene.add.video(
    this.scaler.scaleX(designX),
    this.scaler.scaleY(designY)
  );
  sprite.setOrigin(0.5, 0.5);
  // this.scaler.scaleSpriteByHeight(sprite);
  sprite.keepData = {
    resized: true,
    designX: designX,
    designY: designY,
    size:{
      width: this.assets.video.size.width,
      height: this.assets.video.size.height,
    },
    x: sprite.x
  };
  this.group.add(sprite);
  sprite.depth = this.depth;

  this.videoSprite = sprite;

  // when to load the video ?
  // if (this.videoUrl && window.videoCannotPlay != true) {
  //   this.videoSprite.loadURL(this.videoUrl);
  //   this.videoSprite.on('created', function(video, width, height){
  //     this.videoSprite.setDisplaySize(
  //       this.scaler.vScale() * this.assets.video.size.width,
  //       this.scaler.vScale() * this.assets.video.size.height
  //     );
  //   }, this);
  // }

  if(window.videoCannotPlay == true){
    this.videoSprite.visible = false;
  }

  // video cover sprite
  sprite = this.scene.add.image(
    this.scaler.scaleX(designX),
    this.scaler.scaleY(designY),
    'empty'
  );
  sprite.setOrigin(0.5, 0.5);
  // this.scaler.scaleSpriteByHeight(sprite);
  sprite.keepData = {
    resized: true,
    designX: designX,
    designY: designY,
    size:{
      width: this.assets.cover.size.width,
      height: this.assets.cover.size.height,
    },
    x: sprite.x
  };
  this.group.add(sprite);
  sprite.depth = this.depth;
  //this.dragSprites[l].push(sprite);
  this.coverSprite = sprite;

  // video play sprite
  sprite = this.scene.add.image(
    this.scaler.scaleX(designX),
    this.scaler.scaleY(designY),
    'empty'
  );
  sprite.setOrigin(0.5, 0.5);
  sprite.keepData = {
    resized: true,
    designX: designX,
    designY: designY,
    x: sprite.x
  };
  this.group.add(sprite);
  sprite.depth = this.depth;

  sprite.on("pointerdown", pointer => {
    this.videoSprite.keepData.pressed = true;
    //console.log(pointer);
    //console.log(this);
  }, this);

  sprite.on("pointerup", pointer => {
    //console.log(pointer);
    //console.log(this);
    (this.dragMaxOffset <= 5) && this.videoUrl && (this.togglePlay());
  }, this);

  // set drag events
  sprite.on('drag', function (pointer, dragX, dragY) {
    //console.log("draggin" + dragX);
    let offset = dragX - this.playSprite.keepData.x;
    (Math.abs(offset) > this.dragMaxOffset) && (this.dragMaxOffset = Math.abs(offset));
    this.emit('drag', {offset: offset});
  }, this);

  sprite.on('dragstart', function (pointer, dragX, dragY) {
    //console.log(`drag start.. ${pointer.x} ${pointer.y}`);
    this.dragMaxOffset = 0;
    this.playSprite.keepData.x = this.playSprite.x;
    this.emit('dragstart');
  }, this);

  sprite.on('dragend', function (pointer, dragX, dragY) {
    this.emit('dragend');
  }, this);

  this.playSprite = sprite;
  return [this.frameSprite, this.videoSprite, this.coverSprite, this.playSprite];
};

VideoSprite.prototype.togglePlay = function () {

  if (!this.videoLoaded) {
    // simply load it?
    this.videoLoaded = true;
    if (this.videoUrl && window.videoCannotPlay != true) {
      this.videoSprite.loadURL(this.videoUrl);
      this.videoSprite.on('created', function(video, width, height){
        this.videoSprite.setDisplaySize(
          this.scaler.vScale() * this.assets.video.size.width,
          this.scaler.vScale() * this.assets.video.size.height
        );
      }, this);
    }
  }

  if (this.videoSprite && this.videoSprite.keepData.pressed) {
    this.videoSprite.keepData.pressed = false;
    if (currentVideo && currentVideo != this.videoSprite) {
      currentVideo.stop();
    }
    if (currentVideo != this.videoSprite) {
      currentVideo = this.videoSprite;
    }
    // seems we don't need play it? does it works on mobile as well?
    //  currentVideo.play();

    this.videoSprite.setPaused(!this.videoSprite.isPaused());
    // we simply close the cover is video played
    if(this.coverSprite && this.coverSprite.visible == true){
      this.coverSprite.visible = false;
    }
    this.updateControls();
  }

};

VideoSprite.prototype.updateControls = function () {
  if (this.videoUrl && window.videoCannotPlay != true) {
    if (!this.videoLoaded) {
      // just show it
      this.playSprite.visible = true;
    } else if (this.videoSprite.isPaused()) {
      this.playSprite.visible = true;
      this.frameSprite.disableInteractive();
    } else {
      this.playSprite.visible = false;
      this.frameSprite.setInteractive({ draggable: true });
    }
  }
};

VideoSprite.prototype.update = function () {
  if (this.frameSprite) {
    let bounds = this.frameSprite.getBounds();
    if (bounds.left + bounds.width < 0) {
      // console.log("out of bound then we stop video");
      this.videoSprite.stop();
    }
    if (this.scene.cameras.main && bounds.left > this.scene.cameras.main.worldView.width) {
      this.videoSprite.stop();
    }
    this.updateControls();
  }
};

VideoSprite.prototype.loadTextures = function () {
  let texture = this.assets.frame.texture;
  texture = (texture && texture.length > 0) ? texture : 'empty';
  this.frameSprite.setTexture(texture);
  this.scaler.scaleSpriteByHeight(this.frameSprite);

  // we don't need reset the hit area as the freame is not enabled interactive
  // at current stage
  //let bounds = this.frameSprite.getBounds();
  //this.frameSprite.input.hitArea.setSize(bounds.width, bounds.height);

  // cover sprite
  texture = this.assets.cover.texture;
  texture = (texture && texture.length > 0) ? texture : 'empty';
  this.coverSprite.setTexture(texture);
  this.coverSprite.setDisplaySize(
    this.scaler.vScale() * this.assets.cover.size.width,
    this.scaler.vScale() * this.assets.cover.size.height
  );

  // play button
  this.playSprite.setTexture('btn_play');
  this.scaler.scaleSpriteByHeight(this.playSprite);
  this.playSprite.setInteractive({ draggable: true });

  if(!this.videoUrl || window.videoCannotPlay == true){
    this.playSprite.visible = false;
  }
};


VideoSprite.prototype.unloadTextures = function () {
  //console.log("Unload video sprite");
  //this.frameSprite.setTexture('empty');
  //this.coverSprite.setTexture('empty');
  //this.playSprite.setTexture('empty');
};

export default VideoSprite;
